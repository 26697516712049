export default  {
	data() {
		return {
			modalCounter: 0
		}
	},
    mounted() {
        this.$root.$on('rootSetContentScrollable', (value) => {
            this.setContentScrollable(value);
        });
    },
	methods: {
		setContentScrollable(isModalOpen) {
			if (isModalOpen) {
                this.modalCounter++;
            } else {
                this.modalCounter--;
            }

            if (this.modalCounter > 0) {
                document.body.classList.add('modal-open');
            } else if (this.modalCounter == 0) {
                document.body.classList.remove('modal-open');
            }
		}
	}
}