import Helper from '@/services/helper';
import RouterPath from '@/router/path';
import UserService from '@/services/userService';
import GTMService from '@/services/gtmService';

export default {
    async toggleAddCartEvent(vueObject, param, gtmData) {
        let loader = vueObject.modalLoader.render();
        const result = await this.addToCart(param);
        
        if (result.status == 401) {
            Helper.setCartDataStorage(param);
            vueObject.router.push(RouterPath.LOGIN);
        } else if (result.data.success) {
            GTMService.gtagReportPurchaseProduct(vueObject.gtag);
            GTMService.gtmAddCart(vueObject.gtm, gtmData);

            vueObject.root.$emit('rootSetCartIcon');
            vueObject.router.push(RouterPath.CART);
        } else {
            vueObject.swal(Helper.warningAlert('โปรดทำรายการใหม่อีกครั้ง', result.data.message));
        }

        loader.hide();
    },
    async addToCart(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/add-cart', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getOrderDetail(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/get-order-detail', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getCartData() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/order/get-cart-data', UserService.setHeaderUserToken());
        
        return result;
    },
    async adjustQty(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/adjust-qty', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async removeProduct(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/remove-product', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async toggleService(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/toggle-cart-service', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async toggleDiscount(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/toggle-cart-discount', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async changePaymentMethod(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/change-payment-method', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async submitSchedule(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/submit-schedule', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async submitCustomer(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/submit-customer', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async submitTaxInfo(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/submit-tax', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getTokenCardPayment(param) {
        const result = await Helper.postAPI(Helper.gbConfig().apiUrl, '/v2/tokens', param);

        return result;
    },
    async getPayCardToken(data) {
        const param = {
            rememberCard: false,
            card: {
                name: '',
                number: data.cardNo,
                expirationMonth: data.expiryMonth,
                expirationYear: data.expiryYear,
                securityCode: data.ccv
            }
        };
        const header = {
            Authorization: 'Basic ' + btoa(Helper.gbConfig().publicKey + ':')
        };
        const result = await Helper.postAPI(Helper.gbConfig().apiUrl, '/v2/tokens', param, header);
        
        return result;
    },
    async chargeCardPayment(token, paymentMethod) {
        const param = {
            token: token,
            paymentMethod: paymentMethod
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/charge-card-payment', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async generateQRData() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/order/generate-qr-data', UserService.setHeaderUserToken());
        
        return result;
    },
    async generateBankingData(bankCode, paymentMethod) {
        const param = {
            bankCode: bankCode,
            paymentMethod: paymentMethod
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/generate-banking-data', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async generateInstallmentData(bankCode, term, paymentMethod) {
        const param = {
            bankCode: bankCode,
            term: term,
            paymentMethod: paymentMethod
        }
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/generate-installment-data', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async addProductSize(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/add-product-size', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getCartStatus() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/order/get-cart-status', UserService.setHeaderUserToken());
        
        return result;
    },
    async getServiceSlot(scheduleDate) {
        const param = {
            scheduleDate: scheduleDate
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/get-service-slot', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async submitDeliveryInfo(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/submit-delivery-info', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async trackingDeliveryButton(id) {
        const param = {
            id: id
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/pre-delivery', param);

        return result;
    }
}