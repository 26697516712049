import LoaderBackdrop from '@/components/utility/LoaderBackdrop';

export default {
    install (Vue) {
        Vue.component('load-backdrop', LoaderBackdrop);

        Vue.prototype.$modalLoader = {
            render() {
                const instance = new (Vue.extend(LoaderBackdrop))({
                    el: document.createElement('div')
                });

                instance.$el.setAttribute('id', 'ajaxLoading');
                
                if (!document.getElementById('ajaxLoading')) {
                    document.body.appendChild(instance.$el); 
                }

                return instance;
            }
        };
    }
}