import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueGtag from 'vue-gtag';
import VueGtm from '@gtm-support/vue2-gtm';
import VueMeta from 'vue-meta';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import VueSweetalert2 from 'vue-sweetalert2';
import ToggleButton from 'vue-js-toggle-button';
import 'sweetalert2/dist/sweetalert2.min.css';
import PerfectScrollbar from 'vue2-perfect-scrollbar';
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
import MarqueeText from 'vue-marquee-text-component';
import VueDragscroll from 'vue-dragscroll';
import Datepicker from 'vuejs-datepicker';
import VueScrollTo from 'vue-scrollto';
import VueMobileDetection from 'vue-mobile-detection';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import ModalFullPicture from '@/components/utility/ModalFullPicture';
import LoaderContent from '@/components/utility/LoaderContent';
import SwitchButton from '@/components/utility/SwitchButton';
import Checkbox from '@/components/utility/Checkbox';
import Dropdown from '@/components/utility/Dropdown';
import TimePicker from '@/components/utility/TimePicker';
import AddressAutoComplete from '@/components/utility/AddressAutoComplete';
import Paging from '@/components/utility/Paging';
import TakePicture from '@/components/utility/TakePicture';
import LoaderSpinner from '@/components/utility/LoaderSpinner';
import SingleUpload from '@/components/utility/SingleUpload';
import BlankTemplate from '@/components/layouts/Blank';
import SellerBlankTemplate from '@/components/layouts/SellerBlank';
import SellerMenuTemplate from '@/components/layouts/SellerMenu';
import DefaultTemplate from '@/components/layouts/Default';
import ModalLoader from '@/components/utility/js/modalLoader';
import AppUtility from '@/components/utility/js/appUtility';
import store from '@/store';
import '@/theme/Core.scss';

library.add(fab, fas);

Vue.config.productionTip = false

Vue.use(VueGtag, { 
	config: { id: 'UA-136061254-1' },
	pageTrackerUseFullPath: true,
	enabled: location.origin.indexOf('dev.yellowtire.com') > -1 || location.origin.indexOf('yellowtire:') > -1 ? false : true
}, router);

let gtmKey = [];

if (location.origin.indexOf('dev.yellowtire.com') > -1) {
	gtmKey.push('GTM-NT2HHGT'); // We ACT Dev
	gtmKey.push('GTM-5K2KZTZ'); // GTM Event Tracking
} else if (location.origin.indexOf('yellowtire.com') > -1) {
	gtmKey.push('GTM-52S44V9'); // Original
	gtmKey.push('GTM-5K2KZTZ'); // GTM Event Tracking
}
let isEnable = location.origin.indexOf('dev.yellowtire.com') > -1 || location.origin.indexOf('yellowtire:') > -1 ? false : true;

Vue.use(VueGtm, {
	id: gtmKey,
	enabled: isEnable,
	defer: false,
	compatibility: false,
	nonce: '2726c7f26c',
	debug: false,
	loadScript: true,
	vueRouter: router
});
Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(PerfectScrollbar);
Vue.use(VueAwesomeSwiper);
Vue.use(ModalLoader);
Vue.use(VueDragscroll);
Vue.use(VueSweetalert2, {
	customClass: 'custom-swal'
});
Vue.use(ToggleButton);
Vue.use(VueScrollTo);
Vue.use(VueMobileDetection);
Vue.use(VueReCaptcha, {
	siteKey: '6LchNXsjAAAAAFOcnM4GWv6AZ9LLPxS95IgNp9Go'
});
Vue.use(AppUtility);

Vue.component('blank-layout', BlankTemplate);
Vue.component('seller-blank-layout', SellerBlankTemplate);
Vue.component('seller-menu-layout', SellerMenuTemplate);
Vue.component('default-layout', DefaultTemplate);
Vue.component('loader-content', LoaderContent);
Vue.component('switch-button', SwitchButton);
Vue.component('checkbox', Checkbox);
Vue.component('dropdown', Dropdown);
Vue.component('timepicker', TimePicker);
Vue.component('address-auto-complete', AddressAutoComplete);
Vue.component('paging', Paging);
Vue.component('take-picture', TakePicture);
Vue.component('loader-spinner', LoaderSpinner);
Vue.component('single-upload', SingleUpload);
Vue.component('modal-full-picture', ModalFullPicture);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('marquee-text', MarqueeText);
Vue.component('datepicker', Datepicker);
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
