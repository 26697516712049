<template>
    <div class="cookie-policy" :class="{ 'page-product-detail': isPageTireDetail() }" v-if="isActive">
        <div class="box">
            <p>
                เว็บไซต์นี้มีการใช้คุกกี้ โปรดยอมรับนโยบายคุกกี้เพื่อประสบการณ์การใช้บริการที่ดีที่สุดของท่าน ท่านสามารถศึกษาวิธีการตั้งค่าการควบคุมคุกกี้ของท่านผ่าน <router-link :to="routerPath.STATIC_COOKIE" title="นโยบายการใช้คุกกี้">นโยบายการใช้คุกกี้ของเราที่นี่</router-link>
            </p>
            <div class="text-center"><button class="btn btn-main btn-accept" @click="acceptCookie()">ยอมรับ</button>
            </div>
        </div>
    </div>
</template>

<script>
import RouterPath from '@/router/path';

export default {
    data() {
        return {
            routerPath: RouterPath,
            isActive: false
        }
    },
    mounted() {
        if (localStorage.cookie) {
            const cookieObject = JSON.parse(localStorage.cookie);
            this.isActive = !cookieObject.accept;
        }
        else {
            this.isActive = true;
        }
    },
    methods: {
        acceptCookie() {
            localStorage.cookie = JSON.stringify({
                accept: true
            });
            
            this.isActive = false;

            this.$root.$emit('rootSetupTracking');
        },
        isPageTireDetail() {
            if (this.$route.meta.alias === 'tireDetail') {
                return true;
            }

            return false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.cookie-policy {
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;

    &.page-product-detail {
        @media only screen and (max-width: $screenExtraSmall) {
            bottom: 59px;
        }
    }

    .box {
        @include boxShadow(0 0 15px rgba(0, 0, 0, 0.1));
        margin: auto;
        padding: 15px;
        background: rgba(0, 0, 0, 0.9);
        text-align: center;

        p {
            font-size: 14px;
            margin-bottom: 15px;
            color: #fff;
        }

        a {
            text-decoration: underline;
            color: #ddd;
        }

        .cookie-list {
            max-width: 500px;
            margin: 20px auto;
            background: #222;
            padding: 15px;
            font-size: 14px;

            h4 {
                margin: 0 0 5px 0;
                font-weight: bold;
                color: #fff;
            }

            .list {
                .item {
                    display: flex;
                    border-bottom: 1px solid #555;
                    padding: 10px 0;

                    &:last-child {
                        padding-bottom: 10px;
                        border-bottom: none;
                    }

                    .info {
                        width: 70%;
                        text-align: left;

                        label {
                            margin: 0;
                            color: #ccc;

                            .icon {
                                margin-left: 7px;
                                cursor: pointer;
                            }
                        }
                        
                        article {
                            background: #333;
                            color: #ccc;
                            font-size: 12px;
                            padding: 10px;
                            margin-top: 5px;
                        }
                    }

                    .action {
                        width: 30%;
                    }
                }
            }
        }
    }

    .btn-accept {
        padding: 5px 20px 6px;
    }
}
</style>