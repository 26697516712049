<template>
    <div class="content-loader">
        <img src="@/assets/loading.gif" 
            class="spinner"
            :style="{ '--sizeWidth': sizeWidth, '--sizeHeight': sizeHeight }"
            alt="loading"
            title="loading" />
    </div>
</template>

<script>
export default {
    props: {
        sizeWidth: {
            type: String,
            default: '30px'
        },
        sizeHeight: {
            type: String,
            default: '30px'
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

$width: var(--sizeWidth);
$height: var(--sizeHeight);

.content-loader {
    text-align: center;

    .spinner {
        display: inline-block;
        position: relative;
        width: $width;
        height: $height;
    }
}
</style>