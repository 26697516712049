import Helper from '@/services/helper';
import UserService from '@/services/userService';

export default {
    async getTireBrand() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/product/tire-brand');
        
        return result;
    },
    async getFooterTags() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/product/footer-tags');
        
        return result;
    },
    async searchProduct(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/search-product', param);
        
        return result;
    },
    async getContentSearch(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-content-search', param);
        
        return result;
    },
    async getProductDetail(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-product-detail', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getPopularTire(maxItem, isShowAds) {
        const param = {
            maxItem: maxItem,
            isShowAds: isShowAds
        }
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-popular-tire', param);
        
        return result;
    },
    async getRelatedProductByArticle(articleId) {
        const param = {
            articleId: articleId
        }
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/related-product-by-article', param);
        
        return result;
    },
    async getWeeklyPopularTire(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-weekly-popular-tire', param);
        
        return result;
    },
    async getMostSearchTire() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/product/get-most-search-tire');
        
        return result;
    },
    async searchStore(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/search-store', param);
        
        return result;
    },
    async addFavorite(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/favorite/add-product', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async removeFavorite(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/favorite/remove-product', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getFavoriteContent() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/favorite/get-content', UserService.setHeaderUserToken());
        
        return result;
    },
    async getFavoriteProducts(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/favorite/get-product', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getPDFBase64(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-document-file', param);
        
        return result;
    },
    async getProductSizeByCriteria(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-product-size-by-criteria', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async getSellerFilters(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-store-filter', param);
        
        return result;
    },
    async getRelatedProduct(relatedTireId, networkId, sellerId) {
        const param = {
            relatedTireId: relatedTireId,
            networkId: networkId,
            sellerId: sellerId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-related-products', param);
        
        return result;
    },
    async getRelatedContent(relatedTireId) {
        const param = {
            relatedTireId: relatedTireId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/get-related-contents', param);
        
        return result;
    },
    async calculateSizeChange(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/product/calculate-size-change', param);
        
        return result;
    }
}