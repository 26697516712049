<template>
    <transition
        :name="transitionName"
        :mode="transitionMode"
        :enter-active-class="transitionEnterActiveClass"
        @beforeLeave="beforeLeave"
        @enter="enter"
        @afterEnter="afterEnter">
        <slot />
    </transition>
</template>

<script>
export default {
    data() {
        return {
            prevHeight: 0,
            transitionName: 'fade',
            transitionMode: 'out-in',
            transitionEnterActiveClass: 'fade-enter-active'
        };
    },
    methods: {
        beforeLeave(element) {
            this.prevHeight = getComputedStyle(element).height;
        },
        enter(element) {
            const { height } = getComputedStyle(element);

            element.style.height = this.prevHeight;

            setTimeout(() => {
                element.style.height = height;
            });
        },
        afterEnter(element) {
            element.style.height = 'auto';
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.fade-enter-active, .fade-leave-active {
    opacity: 1;
    overflow: hidden;
    @include transition(all 0.1s linear);
    @include transform(translateX(0));
}

.fade-enter, .fade-leave-active {
    opacity: 0;
    @include transform(translateY(100%));
}
</style>