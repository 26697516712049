<template>
    <div ref="dropdown" v-if="isFormReady">
        <div v-if="selectedOption === undefined || selectedOption == null || selectedOption === ''" class="form-option" :class="classes.wrapper">
            <input
                class="desktop"
                :disabled="disabled"
                :class="[classes.input, isRequired]"
                :placeholder="placeholder"
                :required="required"
                :readonly="disabled || noTyping"
                @focus="seedSearchText()"
                @keydown.enter.prevent="setOption()"
                @keyup.enter.prevent="setOption()"
                @keyup.down="movePointerDown()"
                @keyup.tab.stop="closeOut(false)"
                @keyup.esc.stop="closeOut(false)"
                @keyup.up="movePointerUp()"
                autocomplete="off"
                v-model="searchText"
            />
            <input
                class="mobile"
                :disabled="disabled"
                :class="[classes.input, isRequired]"
                :placeholder="placeholder"
                :required="required"
                readonly
                @focus="seedSearchText()"
                @keydown.enter.prevent="setOption()"
                @keyup.enter.prevent="setOption()"
                @keyup.down="movePointerDown()"
                @keyup.tab.stop="closeOut(false)"
                @keyup.esc.stop="closeOut(false)"
                @keyup.up="movePointerUp()"
                autocomplete="off"
                v-model="searchText"
            />

            <div
                @click="toggleDropdown"
                :class="[classes.icons, isDisabled]"
                class="icons"
            >
                <b-icon icon="chevron-down" class="icon"></b-icon>
            </div>

            <template v-if="matchingOptions && matchingOptions.length > 0">
                <div class="dropdown-backdrop" @click="closeOut(false)" @touchmove="handleTouchBackdrop($event)"></div>
                <div class="dropdown-header">
                    {{ placeholder || '-- OPTION --' }}
                </div>

                <div
                    tabindex="-1"
                    ref="options"
                    v-if="matchingOptions && matchingOptions.length > 0"
                    class="dropdown"
                    :class="[classes.dropdown]"
                >
                    <perfect-scrollbar class="dropdown-wrapper">
                        <span
                            tabindex="-1"
                            v-for="(option, index) in matchingOptions"
                            :key="index"
                            :class="index === pointer ? 'active' : ''"
                            @mouseover="setPointerIndex(index)"
                            @keyup.enter="setOption()"
                            @keyup.up="movePointerUp()"
                            @keyup.down="movePointerDown()"
                            @click.prevent="setOption()"
                        >
                            <slot name="option" v-bind="{option, index}">{{ getOptionDescription(option[optionKey]) }}</slot>
                        </span>
                    </perfect-scrollbar>
                </div>
            </template>
        </div>

        <div :class="classes.wrapper" class="form-option" v-if="selectedOption || selectedOption === 0">
            <input
                ref="match"
                readonly
                @click="closeOut(true)"
                :class="[classes.input, disabled ? 'readonly' : '']"
                :required="required"
                :value="getOptionDescription(selectedOption)"
            />
            <input type="hidden" :name="name" ref="selectedValue" :value="getOptionValue(selectedOption)" />

            <div
                :class="[classes.icons, isDisabled]"
                class="icons"
            >
                <b-icon icon="x" class="icon x" @click="removeData()" v-if="!noDelete"></b-icon>
                <b-icon icon="chevron-down" class="icon" @click="closeOut(true)"></b-icon>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            required: true
        },
        name: {
            type: String,
            required: false,
            default: () => ''
        },
        options: {
            type: Array,
            required: false,
            default: () => []
        },
        optionLabel: {
            type: String,
            required: false,
            default: () => 'name'
        },
        optionKey: {
            type: String,
            required: false,
            default: () => 'id'
        },
        placeholder: {
            type: String,
            required: false,
            default: () => ''
        },
        classes: {
            type: Object,
            required: false,
            default: () => {
                return {
                    wrapper: '',
                    dropdown: '',
                    icons: '',
                    input: ''
                }
            }
        },
        initial: {
            type: String,
            required: false,
            default: () => null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false
        },
        noTyping: {
            type: Boolean,
            required: false,
            default: () => false
        },
        noDelete: {
            type: Boolean,
            required: false,
            default: () => false
        },
        required: {
            type: Boolean,
            required: false,
            default: () => false
        },
        tabindex: {
            type: String,
            required: false,
            default: () => {
                return '';
            }
        },
        getOptionDescription: {
            type: Function,
            default: function (option) {
                const selection = this.options.filter(x => x[this.optionKey] == option);

                if (selection.length > 0) {
                    return selection[0][this.optionLabel];
                }

                return option;
            }
        },
        getOptionValue: {
            type: Function,
            default: function (option) {
                const selection = this.options.filter(x => x[this.optionKey] == option);

                if (selection.length > 0) {
                    return selection[0][this.optionKey];
                }

                return option;
            }
        },
        filterBy: {
            type: Function,
            default: function (option) {
                if (this.optionLabel) {
                    return option[this.optionLabel]
                        .toString()
                        .toLowerCase()
                        .includes(this.searchText.toString().toLowerCase());
                }

                return option
                        .toString()
                        .toLowerCase()
                        .includes(this.searchText.toString().toLowerCase());
            }
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keyup', this.handleClickOutside);
        
        if ((this.value || this.value === 0) && this.options.includes(this.value)) {
            this.selectedOption = this.value;
            return;
        }

        this.searchText = this.initial;
        this.setInitSelectedOption();
    },
    destroyed() {
        document.removeEventListener('keyup', this.handleClickOutside);
        document.removeEventListener('click', this.handleClickOutside);
    },
    data() {
        return {
            isFormReady: false,
            searchReady: false,
            searchText: null,
            selectedOption: null,
            selectedOptionPrev: null,
            isIgnoreChange: false,
            dropdownOpen: false,
            pointer: -1
        };
    },
    watch: {
        value(curr) {
            this.selectedOption = curr;
        },
        options() {
            this.setInitSelectedOption();
        },
        searchText(curr, prev) {
            if (curr !== prev) {
                this.pointer = -1;
            }
        },
        selectedOption(curr) {
            if (!this.isIgnoreChange) {
                this.$emit('input', curr);
                this.$emit('change', curr);
            }
            
            this.isIgnoreChange = false;
        },
        dropdownOpen(curr, prev) {
            if (curr === prev) {
                return;
            }
            if (!curr) {
                this.searchText = null;
                return;
            }
            if (!this.searchText) {
                this.searchText = '';
            }
        }
    },
    computed: {
        isRequired() {
            if (!this.required) {
                return '';
            }

            if (this.selectedOption) {
                return '';
            }

            return 'required';
        },
        isDisabled() {
            if (!this.disabled) {
                return '';
            }

            return 'disabled';
        },
        matchingOptions() {
            if (!this.searchReady || this.searchText === null) {
                return null;
            }

            if (!this.searchText.length) {
                return [...this.options];
            }

            return this.options.filter(option => this.filterBy(option));
        }
    },
    methods: {
        setPointerIndex(index) {
            this.pointer = index;
        },
        seedSearchText() {
            if (this.disabled) {
                return;
            }

            this.searchReady = true;

            if (this.searchText !== null) {
                return;
            }

            this.searchText = '';
        },
        toggleDropdown() {
            if (this.disabled) {
                return;
            }

            this.seedSearchText();
        },
        closeOut(isTrigger) {
            if (this.disabled) {
                return;
            }

            this.isIgnoreChange = true;
            this.selectedOptionPrev = this.selectedOption;
            this.selectedOption = null;
            this.dropdownOpen = false;
            this.searchText = null;

            if (isTrigger) {
                setTimeout(() => this.toggleDropdown());
            }
        },
        movePointerDown() {
            if (!this.matchingOptions) {
                return;
            }
            if (this.pointer >= this.matchingOptions.length - 1) {
                return;
            }

            this.pointer++;
        },
        movePointerUp() {
            if (this.pointer > 0) {
                this.pointer--;
            }
        },
        setInitSelectedOption() {
            let item = -1;

            for (var index = 0; index < this.options.length; index++) {
                if (this.value || this.value === 0) {
                    if (this.options[index][this.optionKey] == this.value) {
                        item = index;
                    }
                }
            }
            
            if (item > -1) {
                this.searchReady = true;
                this.pointer = item;
                this.searchText = '';
                this.setOption();
            }

            this.isFormReady = true;
        },
        setOption() {
            if (!this.matchingOptions || !this.matchingOptions.length) {
                return;
            }

            if (this.pointer === -1) {
                this.pointer++;
            }

            this.selectedOption = this.matchingOptions[this.pointer][this.optionKey];

            this.searchText = null;
            this.dropdownOpen = false;
            this.pointer = -1;
        },
        handleClickOutside(e) {
            if (this.$el.contains(e.target)) {
                return;
            }

            this.handleUnselectOption();

            this.dropdownOpen = false;
            this.searchText = null;            
        },
        handleUnselectOption() {
            if (!this.selectedOption && this.selectedOptionPrev) {
                this.isIgnoreChange = true;
                this.selectedOption = this.selectedOptionPrev;
            }
        },
        handleTouchBackdrop(e) {
            e.preventDefault();
            e.stopPropagation();
        },
        removeData() {
            this.searchText = null;
            this.selectedOption = null;
            this.selectedOptionPrev = null;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.form-option {
    position: relative;

    input {
        display: block;
        padding-right: 20px;
        width: 100%;
        outline: none;

        &[readonly] {
            cursor: default !important;
        }

        &.readonly {
            cursor: not-allowed !important;
            background: #eee !important;
        }

        &.desktop {
            display: block;

            @media only screen and (max-width: $screenExtraSmall) {
                display: none;
            }
        }

        &.mobile {
            display: none;

            @media only screen and (max-width: $screenExtraSmall) {
                display: block;
            }
        }
    }

    .icons {
        position: absolute;
        z-index: 0;
        font-size: 12px;
        right: 5px;
        top: 0;
        bottom: 0;
        height: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;

        &.disabled {
            cursor: not-allowed;
        }

        .icon {
            margin-left: 4px;

            &.x {
                opacity: 0.7;
                font-size: 14px;
            }
        }
    }

    .dropdown-backdrop {
        background: rgba(0, 0, 0, 0.8);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: none;

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }
    }

    .dropdown-header {
        position: fixed;
        z-index: 1001;
        top: 30%;
        left: 0;
        right: 0;
        background: #eee;
        font-size: 14px;
        padding: 8px 10px;
        border-bottom: 1px solid #ccc;
        text-align: center;
        display: none;

        @media only screen and (max-width: $screenExtraSmall) {
            display: block;
        }
    }

    .dropdown {
        position: absolute;
        background: $inputBackgroundColor;
        margin: 0;
        margin-top: 1px;
        padding: 0;
        z-index: 1001;
        width: 100%;
        @include boxShadow(0 0 5px rgba(0, 0, 0, 0.7));

        @media only screen and (max-width: $screenExtraSmall) {
            position: fixed;
            margin-top: 37px;
            top: 30%;
            left: 0;
            right: 0;
            bottom: 0;
            width: auto;
            max-height: none;
            @include boxShadow(none);
        }

        .dropdown-wrapper {
            max-height: 300px;

             @media only screen and (max-width: $screenExtraSmall) {
                max-height: 100%;
            }

            span {
                display: block;
                cursor: pointer;
                font-size: 15px;
                padding: 5px 10px;
                @include transition(none);

                &:hover, &.active {
                    color: #fff;
                    background: #3a9ed0;
                }

                @media only screen and (max-width: $screenExtraSmall) {
                    border-bottom: 1px dashed #ddd;
                    text-align: center;
                }
            }
        }
    }
}
</style>