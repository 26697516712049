<template>
	<div>
		<template v-if="isShowLoginPopup">
			<div class="dev-login">
				<div class="field">
					<b-icon icon="shield-lock" class="icon"></b-icon>
					<input type="password" v-model="devPassword" @keyup.enter="submitDevLogin()" />
				</div>
			</div>
		</template>
		<template v-else>
			<div v-if="layout">
				<component :is="layout">
					<transition-page>
						<router-view :key="$route.fullPath" />
					</transition-page>
				</component>
			</div>
		</template>
	</div>
</template>

<script>
import HomeService from '@/services/homeService';
import TransitionPage from '@/components/layouts/TransitionPage';

export default {
	components: {
		TransitionPage
	},
	data() {
		return {
			isShowLoginPopup: this.checkTestUrl(),
			devPassword: ''
		};
	},
	computed: {
		layout() {
			if (this.$route.meta.layout) {
				return this.$route.meta.layout + '-layout';
			}
			
			return null;
		}
	},
	mounted() {
		this.inactivityReload();
	},
	methods: {
		checkTestUrl() {
			if (location.origin.indexOf('dev.yellowtire.com') > -1) {
				if (!localStorage.devUser) {
					return true;
				}
			}

			return false;
		},
		async submitDevLogin() {
			if (this.devPassword) {
				const result = await HomeService.submitDevLogin(this.devPassword);

				if (result.data.isValid) {
					this.isShowLoginPopup = false;
					localStorage.devUser = 'welcome!';
				}
			}
		},
		inactivityReload() {
			const inactivityTime = 3600000;
			const timeout = 1000;
			let time = new Date().getTime();
			let isActive = true;

			const setActivityTime = () => {
				time = new Date().getTime();
			};

			document.body.addEventListener('mousemove', setActivityTime);
			document.body.addEventListener('keypress', setActivityTime);

			// Add a listener for the focus event
			window.addEventListener('focus', () => {
				if (new Date().getTime() - time >= inactivityTime) {
					location.reload();
				}

				isActive = true;
			});

			// Add an unload event listener
			window.addEventListener('blur', () => {
				isActive = false;
			});

			const refresh = () => {
				if (isActive && new Date().getTime() - time >= inactivityTime) {
					location.reload();
				} else {
					setTimeout(refresh, timeout);
				}
			};

			setTimeout(refresh, timeout);
		}
	}
};
</script>

<style lang="scss" scoped>
.dev-login {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10000;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;

	.field {
		max-width: 300px;
		text-align: center;

		.icon {
			font-size: 100px;
			color: #ccc;
			display: block;
			margin: 0 auto 15px;
		}

		input {
			display: block;
			width: 100%;
			padding: 10px 15px;
			border: 1px solid #666;
			background: #fff;
			text-align: center;
		}
	}
}
</style>