import Vue from 'vue';
import Vuex from 'vuex';
import MasterService from '@/services/masterService';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        thaiAddressData: null,
        thaiAddressVersion: 1,
        isFetchingThaiAddressData: false
    },
    mutations: {
        setThaiAddressData(state, payload) {
            state.thaiAddressData = payload;
        },
        setIsFetchingThaiAddressData(state, payload) {
            state.isFetchingThaiAddressData = payload;
        },
    },
    actions: {
        async fetchThaiAddressData({ commit }) {
            if (!this.state.thaiAddressData && !this.state.isFetchingThaiAddressData) {
                commit('setIsFetchingThaiAddressData', true);

                let isRequireReload = true;
                const storageData = localStorage.thaiAddressData;

                if (storageData) {
                    const result = JSON.parse(localStorage.thaiAddressData);

                    if (result.version === this.state.thaiAddressVersion) {
                        isRequireReload = false;
                        
                        commit('setThaiAddressData', result.json);
                    }
                }

                if (isRequireReload) {
                    const result = await MasterService.getThaiAddressJson();

                    localStorage.thaiAddressData = JSON.stringify({ json: result.data, version: this.state.thaiAddressVersion });

                    commit('setThaiAddressData', result.data);
                }
            }
        },
    },
    getters: {
        getThaiAddressData: (state) => state.thaiAddressData,
    },
});