import Helper from '@/services/helper';

export default {
    async submitDevLogin(devPassword) {
        const param = {
            devPassword: devPassword
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/home/submit-dev-login', param);
        
        return result;
    },
    async getKeywordList(keyword) {
        const param = {
            keyword: keyword
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/home/get-keyword-list', param);
        
        return result;
    },
	async getBannerSlider() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/home/get-banner');
        
        return result;
    },
    async getBrands() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/home/get-brand');
        
        return result;
    },
    async getContent() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/home/get-content');
        
        return result;
    }
}