export default {
    DELIVERY_REMARK: 'กรณีจัดส่ง สำหรับกรุงเทพฯ และปริมณฑล ท่านจะได้รับสินค้าภายใน 3-5 วันทำการ และ 4-7 วันทำการ สำหรับจังหวัดอื่นๆ',
    NETWORK_SEARCH_MODE: {
        TIRE: 1,
        STORE: 2
    },
    ITEM_BOX_TYPE: {
        GENERAL: 'general',
        PURCHASE: 'purchase',
        NETWORK: 'network'
    },
    ORDER_TYPE: {
        SERVICE: 1,
        DELIVERY: 2
    },
    ORDER_STATUS: {
        WAITING_CONFIRM: 1,
        WAITING_SERVICE: 2,
        WAITING_DELIVERY: 3,
        COMPLETED_SERVICE: 4,
        COMPLETED_DELIVERY: 5,
        CANCEL: 99
    },
    SELLER_PROFILE_MODE: {
        REGISTER: 1,
        PROFILE: 2
    },
    SELLER_STOCK_FORM_MODE: {
        NEW: 'new',
        EDIT: 'edit'
    },
    SELLER_STOCK_STATUS: {
        ALL: -1,
        LIMITED: 1,
        SUBLET: 2,
        FACTORY: 3,
        NOTSALE: 4,
        LATEST: 99
    },
    SORT_STOCK_DIRECTION: {
        DEFAULT: '',
        ASC: 'asc',
        DESC: 'desc'
    },
    SORT_STOCK_COLUMN: {
        DEFAULT: '',
        ID: 'id',
        BRAND: 'brand',
        SIZE: 'size',
        YEAR: 'year',
        QUANTITY: 'qty',
        PRICE: 'price',
        PRICE_DELIVERY: 'priceDelivery',
        INTEGRATE: 'integrate'
    },
    BENEFIT_TYPE: {
        WARRANTY: 'warranty',
        FREE_GIFT: 'freeGift',
        YLT_GIFT: 'yltGift'
    }
};
