<template>
    <div class="switch-button-control">
        <div class="switch-button" :class="{ enabled: isEnabled, 'readonly': readonly }" @click="toggle()" :style="{ '--color': color }">
            <div class="button"></div>
        </div>
        <div class="switch-button-label">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'isEnabled',
        event: 'toggle'
    },
    props: {
        isEnabled: Boolean,
        color: {
            type: String,
            required: false,
            default: '#4285f4'
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        toggle() {
            if (!this.readonly) {
                this.$emit('toggle', !this.isEnabled);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

$buttonHeight: 25px;
$buttonColor: var(--color);
$buttonBorderThickness: 1px;
$transitionValue: all 0.1s ease-in-out;
$buttonSideLength: calc(
    #{$buttonHeight} - (2 * #{$buttonBorderThickness})
);

.switch-button-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    
    .switch-button {
        width: calc(#{$buttonHeight} * 2);
        height: $buttonHeight;
        border: $buttonBorderThickness solid #ccc;
        cursor: pointer;
        @include boxShadow(inset 0px 0px $buttonBorderThickness 0px rgba(0, 0, 0, 0.33));
        @include transition($transitionValue);
        background: #ccc;

        .button {
            width: $buttonSideLength;
            height: $buttonSideLength;
            border: $buttonBorderThickness solid #fff;
            background: $inputBackgroundColor;
            @include transition($transitionValue);
        }

        &.enabled {
            background-color: $buttonColor;
            border: $buttonBorderThickness solid $buttonColor;
            @include boxShadow(none);

            .button {
                background: #fff;
                @include transform(translateX(
                calc(#{$buttonSideLength} + (2 *#{$buttonBorderThickness}))
                ));
            }
        }

        &.readonly {
            cursor: default;
        }
    }

    .switch-button-label {
        margin-left: 10px;
        font-size: 13px;
    }
}
</style>