import $ from 'jquery';

export default {
    created() {
        this.addEventListenerForImage();
    },
    methods: {
        addEventListenerForImage() {
            if (location.origin.indexOf('yellowtire.com') > -1) {
                $('body').delegate('img', 'contextmenu', function() {
                    return false;
                });
            }
        }
    }
}