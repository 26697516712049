<template>
    <div class="modal-loader modal-mask">
        <div class="content-loader">
            <img src="@/assets/loader.png" class="spinner" alt="loading" title="loading" />
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        hide() {
            this.$destroy();
            
            if (this.$el.parentNode) {
                this.$el.parentNode.removeChild(this.$el);
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
}

.modal-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-loader {
    text-align: center;

    .spinner {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
        animation: spinnerAnimate 5s infinite;
        -webkit-animation: spinnerAnimate 5s infinite;
    }
}

@keyframes spinnerAnimate {
    0% {
        @include transform(rotate(0));
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        @include transform(rotate(900deg));
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        @include transform(rotate(1800deg));
    }
}

/* Firefox < 16 */
@-moz-keyframes spinnerAnimate {
    0% {
        @include transform(rotate(0));
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        @include transform(rotate(900deg));
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        @include transform(rotate(1800deg));
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes spinnerAnimate {
    0% {
        @include transform(rotate(0));
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        @include transform(rotate(900deg));
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        @include transform(rotate(1800deg));
    }
}

/* Internet Explorer */
@-ms-keyframes spinnerAnimate {
    0% {
        @include transform(rotate(0));
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        @include transform(rotate(900deg));
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        @include transform(rotate(1800deg));
    }
}

/* Opera < 12.1 */
@-o-keyframes spinnerAnimate {
    0% {
        @include transform(rotate(0));
        animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
        @include transform(rotate(900deg));
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
        @include transform(rotate(1800deg));
    }
}
</style>