<template>
    <div class="template-seller">
        <seller-header @toggleMobileMenu="toggleMenu()"></seller-header>

        <div class="layout-body">
            <div class="container">
                <div class="seller-bg">
                    <div class="menu" :class="{ 'show': isShowMobileMenu }">
                        <perfect-scrollbar>
                            <h1> บัญชีร้านค้า</h1>

                            <div class="seller-title">
                                <b-icon icon="person-circle" class="user-icon"></b-icon>

                                <div class="seller-info" v-if="sellerInfo">
                                    <span>{{ sellerInfo.staff }}</span>
                                    <div class="register-date">
                                        <label>ลงทะเบียนเมื่อ</label>
                                        <span>{{ sellerInfo.registerDate }}</span>
                                    </div>
                                    <div class="seller-store">
                                        <span>{{ sellerInfo.code }} : {{ sellerInfo.name }}
                                        <br />
                                        <a :href="'/store/' + sellerInfo.link" target="_blank">ลิงค์หน้าร้าน</a></span>
                                    </div>
                                </div>
                            </div>

                            <label class="menu-title" v-if="canAccessOrderMenu()">คำสั่งซื้อ</label>
                            <router-link :to="routerPath.SELLER_ORDERS" active-class="active" v-if="canManageOrder()" @click.native="toggleMenu()">จัดการคำสั่งซื้อ</router-link>
                            <router-link :to="routerPath.SELLER_ORDERS_SERVICE" active-class="active" v-if="canConfirmService()" @click.native="toggleMenu()">ยืนยันการติดตั้ง</router-link>
                            <router-link :to="routerPath.SELLER_ORDERS_DELIVERY" active-class="active" v-if="canConfirmDelivery()" @click.native="toggleMenu()">ยืนยันการส่งสินค้า</router-link>

                            <label class="menu-title" v-if="canAccessProductMenu()">สินค้า</label>
                            <router-link :to="routerPath.SELLER_PRODUCTS" active-class="active" v-if="canManageProduct()" @click.native="toggleMenu()">จัดการสินค้า</router-link>
                            <router-link :to="routerPath.SELLER_PRODUCTS_IMPORT" active-class="active" v-if="canManageExcelProduct()" @click.native="toggleMenu()">ดาวน์โหลด/นำเข้าสินค้า</router-link>
                            <router-link :to="routerPath.SELLER_PRODUCTS_WARRANTY" active-class="active" v-if="canManageProduct()" @click.native="toggleMenu()">การรับประกัน</router-link>
                            <router-link :to="routerPath.SELLER_PRODUCTS_PROMOTION" active-class="active" v-if="canManagePromotion()" @click.native="toggleMenu()">โปรโมชั่น</router-link>
                            <router-link :to="routerPath.SELLER_PRODUCTS_SERVICE" active-class="active" v-if="canManageProduct()" @click.native="toggleMenu()">บริการอื่นๆ</router-link>

                            <label class="menu-title" v-if="canAccessAccountantMenu()">การเงิน</label>
                            <router-link :to="routerPath.SELLER_INCOME" active-class="active" v-if="canAccessAccountantMenu()" @click.native="toggleMenu()">รายละเอียดทางการเงิน</router-link>

                            <label class="menu-title" v-if="canAccessDashboardMenu()">วิเคราะห์ธุรกิจ</label>
                            <router-link :to="routerPath.SELLER_DASHBOARD" active-class="active" v-if="canManageDashboard()" @click.native="toggleMenu()">Dashboard</router-link>
                            <router-link :to="routerPath.SELLER_REPORT_CUSTOMER" active-class="active" v-if="canAccessReport()" @click.native="toggleMenu()">รายงานลูกค้า</router-link>
                            <router-link :to="routerPath.SELLER_REPORT_SALE" active-class="active" v-if="canAccessReport()" @click.native="toggleMenu()">รายงานการขาย</router-link>
                            <router-link :to="routerPath.SELLER_REPORT_PROMOTION" active-class="active" v-if="canAccessReport()" @click.native="toggleMenu()">รายงานการขายสินค้าโปรโมชั่น</router-link>
                            <router-link :to="routerPath.SELLER_REPORT_PRODUCT_REMAIN" active-class="active" v-if="canAccessReport()" @click.native="toggleMenu()">รายงานสินค้าคงเหลือ</router-link>
                            <router-link :to="routerPath.SELLER_REPORT_PRODUCT_PROMOTION" active-class="active" v-if="canAccessReport()" @click.native="toggleMenu()">รายงานสินค้าที่กำหนดโปรโมชั่น</router-link>
                            <router-link :to="routerPath.SELLER_REPORT_PRODUCT_MOVEMENT" active-class="active" v-if="canAccessReport()" @click.native="toggleMenu()">รายงานสินค้าไม่เคลื่อนไหว</router-link>
                            <router-link :to="routerPath.SELLER_REPORT_SYSTEM" active-class="active" v-if="canAccessReport()" @click.native="toggleMenu()">รายงานการเข้าระบบ</router-link>
                            <router-link :to="routerPath.SELLER_REVIEW" active-class="active" v-if="canAccessRating()" @click.native="toggleMenu()">คะแนนรีวิว</router-link>

                            <label class="menu-title" v-if="canAccessStoreMenu()">ร้านค้า</label>
                            <router-link :to="routerPath.SELLER_PROFILE" active-class="active" v-if="canManageStoreProfile()" @click.native="toggleMenu()">ข้อมูลร้านค้า</router-link>
                            <router-link :to="routerPath.SELLER_SETTING" active-class="active" v-if="canManageSetting()" @click.native="toggleMenu()">ตั้งค่าการติดตั้งและจัดส่ง</router-link>
                            <router-link :to="routerPath.SELLER_SETTING_PREPAID" active-class="active" v-if="canManageSetting()" @click.native="toggleMenu()">ตั้งค่าการมัดจำ</router-link>
                            <router-link :to="routerPath.SELLER_STAFF" active-class="active" v-if="canManageStaff()" @click.native="toggleMenu()">ตั้งค่าเจ้าหน้าที่ผู้ใช้งาน</router-link>
                            <router-link :to="routerPath.SELLER_DECORATION" active-class="active" v-if="canManageDecoration()" @click.native="toggleMenu()">ตกแต่งร้าน</router-link>
                            <router-link :to="routerPath.SELLER_HOLIDAY" active-class="active" v-if="canManageHoliday()" @click.native="toggleMenu()">วันหยุดพิเศษ</router-link>

                            <label class="menu-title">เงื่อนไขและนโยบาย</label>
                            <a :href="routerPath.STATIC_SELLER_TERM_INSTALLATION" target="_blank">เงื่อนไขการติดตั้งสินค้า</a>
                            <a :href="routerPath.STATIC_SELLER_TERM_DELIVERY" target="_blank">เงื่อนไขการจัดส่งสินค้า</a>

                            <label class="menu-title">บัญชีร้านค้า</label>
                            <button @click="logout()">ออกจากระบบ</button>
                        </perfect-scrollbar>
                    </div>
                    <div class="seller-content">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import RouterPath from '@/router/path';
import MixinContentScroll from '@/components/layouts/mixins/contentScroll';
import SellerHeader from '@/components/seller/Header';

export default {
    mixins: [ MixinContentScroll ],
    components: {
        SellerHeader
	},
    data() {
        return {
            routerPath: RouterPath,
            isShowMobileMenu: false,
            isValidProfile: false,
            sellerInfo: null
        };
    },
    mounted() {
        this.displayTitle();
        this.checkSellerProfile();

        this.$root.$on('checkSellerProfile', () => {
            this.checkSellerProfile();
        });
    },
    methods: {
        async checkSellerProfile() {
            const currentPath = location.pathname;

            if (SellerService.isSellerLogin()
                && !this.isValidProfile
                && currentPath !== RouterPath.SELLER_REGISTER
                && currentPath !== RouterPath.SELLER_LOGIN
                && currentPath !== RouterPath.SELLER_FORM
                && currentPath !== RouterPath.SELLER_DECORATION) {
                const result = await SellerService.checkPictureProfile();
                this.isValidProfile = result.data.isValidProfile;

                if (result.status == 401) {
                    this.$router.push(RouterPath.SELLER_LOGIN);
                } else if (!result.data.isValidProfile) {
                    this.$swal(
                        Helper.infoAlert('ยินดีต้อนรับ!', 'สำหรับร้านค้าใหม่ โปรดทำการอัพโหลดรูปหน้าร้านของท่านเพื่อนำไปแสดงผลบนเว็บไซต์ของเรา')
                    ).then(() => {
                        this.$router.push(RouterPath.SELLER_DECORATION);
                    });
                }
            }
        },
        toggleMenu() {
            this.$root.$emit('checkSellerProfile');
            this.isShowMobileMenu = !this.isShowMobileMenu;
        },
        displayTitle() {
			if (localStorage.shopInfo) {
				this.sellerInfo = JSON.parse(localStorage.shopInfo);
			}
		},
        canAccessOrderMenu() {
            return SellerService.canAccessOrderMenu();
        },
        canManageOrder() {
            return SellerService.canManageOrder();
        },
        canConfirmService() {
            return SellerService.canConfirmService();
        },
        canConfirmDelivery() {
            return SellerService.canConfirmDelivery();
        },
        canAccessProductMenu() {
            return SellerService.canAccessProductMenu();
        },
        canManageProduct() {
            return SellerService.canManageProduct();
        },
        canManageExcelProduct() {
            return SellerService.canManageExcelProduct();
        },
        canManagePromotion() {
            return SellerService.canManagePromotion();
        },
        canAccessAccountantMenu() {
            return SellerService.canAccessAccountantMenu();
        },
        canAccessDashboardMenu() {
            return SellerService.canAccessDashboardMenu();
        },
        canManageDashboard() {
            return SellerService.canManageDashboard();
        },
        canAccessReport() {
            return SellerService.canAccessReport();
        },
        canAccessRating() {
            return SellerService.canAccessRating();
        },
        canAccessStoreMenu() {
            return SellerService.canAccessStoreMenu();
        },
        canManageStoreProfile() {
            return SellerService.canManageStoreProfile();
        },
        canManageSetting() {
            return SellerService.canManageSetting();
        },
        canManageStaff() {
            return SellerService.canManageStaff();
        },
        canManageDecoration() {
            return SellerService.canManageDecoration();
        },
        canManageHoliday() {
            return SellerService.canManageHoliday();
        },
        async logout() {
            let loader = this.$modalLoader.render();

            await SellerService.submitLogout();

            this.toggleMenu();
            this.$router.push('/');

            SellerService.clearSellerToken();

            loader.hide();
        }
    },
    metaInfo() {
		const title = 'จัดการร้านค้า';
		const desc = 'จัดการร้านค้า Yellowtire';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
                { vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
                { vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.template-seller {
    position: relative;
	max-width: 100%;
    min-width: 340px;
    margin: auto;

    .layout-body {
        @media only screen and (max-width: $screenLarge) {
            background: #fff;
        }

        .container {
            max-width: 1600px;
        }

        .seller-bg {
            min-height: 100vh;
            padding-top: 62px;
            background: #fff;
            position: relative;

            .menu {
                position: fixed;
                z-index: 1;
                top: 62px;
                bottom: 0;
                width: 260px;
                padding: 20px;
                background: #4E4C4C;

                @media only screen and (max-width: $screenLarge) {
                    position: fixed;
                    top: 70px;
                    left: 0;
                    bottom: 0;
                    z-index: 99;
                    display: none;
                    width: 250px;

                    &.show {
                        display: block;
                    }
                }

                @media only screen and (max-width: $screenExtraSmall) {
                    top: 60px;
                }

                .seller-title {
                    text-align: center;
                    color: #fff;

                    .user-icon {
                        font-size: 120px;
                        padding: 20px 0;
                    }

                    .seller-info {
                        font-size: 13px;

                        a {
                            padding: 0;
                            margin: 0;
                        }

                        .register-date {
                            margin-bottom: 15px;
                            opacity: 0.7;

                            label {
                                margin-right: 5px;
                            }
                        }

                        .seller-store {
                            border-top: 1px dotted #999;
                            border-bottom: 1px dotted #999;
                            padding: 10px 0;
                        }
                    }
                }

                h1 {
                    text-align: center;
                    font-size: 22px;
                    font-weight: bold;
                    font-family: 'Open Sans', 'Kanit';
                    margin-bottom: 0;
                    color: #fff;
                }

                .menu-title {
                    font-size: 13px;
                    font-weight: bold;
                    color: #ffdc53;
                    display: block;
                    text-decoration: none;
                    background: none;
                    border: none;
                    outline: none;
                    margin: 20px 0 10px;
                }

                a, button {
                    font-size: 13px;
                    padding-left: 15px;
                    margin: 5px 0;
                    color: #ccc;
                    display: block;
                    text-decoration: none;
                    background: none;
                    border: none;
                    outline: none;

                    &.active {
                        font-weight: bold;
                        color: #fff;
                    }
                }
            }

            .seller-content {
                padding: 20px 20px 20px 280px;

                @media only screen and (max-width: $screenLarge) {
                    padding: 20px 0;
                }
            }
        }
    }
}
</style>