import axios from 'axios';
import moment from 'moment';
import Constants from '@/variables/constants';

const helper = {
    metaDefault() {
        return {
            title: 'ราคายางรถยนต์วันนี้ เช็คราคายาง โปรโมชั่นยาง ร้านยาง | YELLOWTiRE',
            desc: 'เช็คราคายางรถยนต์ทุกรุ่น ทุกขนาด ค้นหายางรถยนต์ทุกยี่ห้อ พร้อมข้อมูลยาง และโปรโมชั่น ราคายางอัพเดตล่าสุด รวมร้านยางรถยนต์ใกล้ฉันทั่วไทย | YELLOWTiRE.com ที่สุดเรื่องยาง เช็ค ชอป ชัวร์',
            logo: '/img/social-logo.png',
            keyword: 'ยางรถยนต์,ยาง,ยางรถ,ราคายาง,เช็คราคายางรถยนต์,จัดส่ง,ติดตั้ง,การเปลี่ยนยาง,โปรโมชั่นยางรถยนต์,ร้านยาง,ยางรถยนต์ขอบ15,ยางรถยนต์ขอบ16,ยางรถยนต์ขอบ17,ยางรถยนต์ขอบ18,bridgestone,dunlop,michelin,yokohama,maxxis,goodyear,nitto,continental,toyo,deestone,apollo,firestone,nitto,hankook,ผ่อนยาง 0%,ราคายางรถยนต์, ร้านยางใกล้ฉัน',
            author: 'YELLOWTiRE',
            publisher: 'YELLOWTiRE'
        };
    },
    apiUrl() {
        let apiUrl = '';

        if (location.origin.indexOf(':8888') > -1) {
            apiUrl = 'http://api-yellowtire:8888';
        } else if (location.origin.indexOf('192.168.1.11:8080') > -1) {
            apiUrl = 'http://192.168.1.11:9090';
        } else if (location.origin.indexOf('yellowtire:8080') > -1) {
            apiUrl = 'http://api-yellowtire:9090';
        } else if (location.origin.indexOf('yellowtire-tm:8080') > 0) {
            apiUrl = 'https://dev-api.yellowtire.com';
        } else if (location.origin.indexOf('dev.yellowtire.com') > -1) {
            apiUrl = 'https://dev-api.yellowtire.com';
        } else if (location.origin.indexOf('yellowtire.com') > -1) {
            apiUrl = 'https://api.yellowtire.com';
        }

        return apiUrl;
    },
    gbConfig() {
        // UAT
        let config = {
            publicKey: 'fEkiyf0OlDZFHUpJ8eeKTwNUaxtrmxS9',
            apiUrl: 'https://api.globalprimepay.com'
        };

        if (location.origin.indexOf('dev.yellowtire.com') === -1 && location.origin.indexOf('yellowtire.com') > -1) {
            // PROD
            config = {
                publicKey: 'fqwuhQcyXuItZDuy4Kl9nLwCEXjzh7HP',
                apiUrl: 'https://api.gbprimepay.com'
            };
        }

        return config;
    },
    getProductSearchPageName() {
        return location.pathname.split('/').filter(part => part !== '' && !part.includes('?'))[0];
    },
    getSessionId() {
        const sessionKey = 'sessionId';
        let sessionId = this.getCookie(sessionKey);

        if (!sessionId) {
            sessionId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
            this.setCookie(sessionKey, sessionId); // Store in cookie
        }

        return sessionId;
    },
    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);

        if (parts.length === 2) return parts.pop().split(';').shift();
    },
    setCookie(name, value) {
        document.cookie = `${name}=${value}; path=/; SameSite=Lax;`;
    },
    generateApiHeader(customHeader) {
        const header = {
            LocationData: localStorage.locationData
        };

        // Generate the userSession object
        let userId = 'annonymous';

        if (localStorage.userToken) {
            userId = localStorage.userToken.split('|')[0];
        }

        const userSession = {
            sessionId: this.getSessionId(),
            referrer: document.referrer,
            userId: userId
        };

        // Encode the userSession object to base64
        const encodedUserSession = btoa(JSON.stringify(userSession));

        const config = {
            headers: {
                ...header,
                ...customHeader,
                Usersession: encodedUserSession
            }
        };

        return config;
    },
    getAPI(api, path, customHeader) {
        return new Promise((resolve) => {
            axios
                .get(api + path, this.generateApiHeader(customHeader))
                .then((response) => { 
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    },
    postAPI(api, path, data, customHeader) {
        return new Promise((resolve) => {
            axios
                .post(api + path, data, this.generateApiHeader(customHeader))
                .then((response) => { 
                    resolve(response);
                })
                .catch((error) => {
                    resolve(error.response);
                });
        });
    },
    cloneObject(object) {
        return JSON.parse(JSON.stringify(object));
    },
    getQueryString(name) {
        name = name.replace(/[[\]]/g, '\\$&')
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(location.href);

        if (!results) return null;
        if (!results[2]) return '';
        
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    getCommaNumber(value) {
        if (value) {
            const parts = value.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            return parts.join('.');
        }

        return value;
    },
    getLeadingZero(value, size) {
        let number = value + '';

        while (number.length < size) {
            number = '0' + number;
        }
        
        return number;
    },
    replaceRouteParam(path, routeParam, replaceParam) {
        return path.replace(routeParam, replaceParam);
    },
    replaceProductParam(path, brand, id) {
        let url = path.replace(':brand', brand);
        
        // If it is detail
        if (id) {
            url = url.replace(':id', id);
        }

        return url;
    },
    displayCountDownOTP(time) {
        time %= 3600;
        var min = Math.floor(time / 60);
        var sec = time % 60;

        return ('0' + min).slice(-2) + ':' + ('0' + sec).slice(-2);
    },
    validateUsernameFormat(password) {
        const regex = /^[_a-zA-Z0-9-]+$/;

        if (regex.test(password)) {
            return true;
        }

        return false;
    },
    validatePasswordFormat(password) {
        const message = [];
        let isValid = true;

        if (password.length < 8) {
            isValid = false;
            message.push('รหัสผ่านต้องยาว 8 ตัวอักษรขึ้นไป');
        }
    
        if (!/[a-z]/.test(password)) {
            isValid = false;
            message.push('ตัวอักษร a-z ตัวเล็ก อย่างน้อย 1 ตัวอักษร');
        }
    
        if (!/[A-Z]/.test(password)) {
            isValid = false;
            message.push('ตัวอักษร A-Z ตัวใหญ่ อย่างน้อย 1 ตัวอักษร');
        }
    
        if (!/\d/.test(password)) {
            isValid = false;
            message.push('ตัวเลข อย่างน้อย 1 ตัวอักษร');
        }

        return {
            isValid: isValid,
            message: message
        };
    },
    infoAlert(title, text) {
        return {
            title: title,
            text: text,
            icon: 'info',
            confirmButtonColor: '#41adc5'
        };
    },
    completeAlert(title, text) {
        return {
            title: title,
            text: text,
            icon: 'success',
            confirmButtonColor: '#41adc5'
        };
    },
    warningAlert(title, text, html) {
        return {
            title: title,
            text: text,
            html: html,
            icon: 'warning',
            confirmButtonColor: '#41adc5'
        };
    },
    confirmAlert(title, text) {
        return {
            title: title,
            text: text,
            icon: 'question',
            confirmButtonColor: '#41adc5',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
            showCancelButton: true
        };
    },
    getWarningPolicyMessage(messages) {
        let html = '';

        for (const message of messages) {
            html += `<br />${message}`;
        }

        return html;
    },
    getAppointmentText(time) {
        let text = '';

            switch (time) {
                case 'slot1':
                    text = 'ช่วงเวลา 08:00 - 11:00';
                    break;

                case 'slot2':
                    text = 'ช่วงเวลา 11:00 - 14:00';
                    break;

                case 'slot3':
                    text = 'ช่วงเวลา 14:00 - 17:00';
                    break;

                case 'slot4':
                    text = 'ช่วงเวลา 17:00 - 20:00';
                    break;
            }

            return text;
    },
    setupOption(list) {
        const option = [];

        list.forEach((item) => {
            option.push({
                id: item,
                name: item
            });
        });

        return option;
    },
    isCompletedOrder(status) {
        return status === Constants.ORDER_STATUS.COMPLETED_SERVICE || status === Constants.ORDER_STATUS.COMPLETED_DELIVERY;
    },
    isWaitingOrder(status) {
        return status === Constants.ORDER_STATUS.WAITING_CONFIRM || status === Constants.ORDER_STATUS.WAITING_SERVICE || status === Constants.ORDER_STATUS.WAITING_DELIVERY;
    },
    splitDotParam(param) {
        if (param) {
            const array = param.split('.');

            return array[0];
        }

        return param;
    },
    fileDownloadByApi(file) {
        const tagLink = document.createElement('a');
        tagLink.style.display = 'none';
        tagLink.href = file;

        document.body.appendChild(tagLink);
        tagLink.click();

        tagLink.remove();
    },
    displayPromotionAmountUnit(type) {
        let unit = '';

        switch (type) {
            case 'discountAmount':
            case 'discountOrderAmt':
            case 'discountTurnPart':
                unit = 'บ.'
                break;

            case 'discountPercent':
            case 'discountOrderPercent':
                unit = '%'
                break;
        }

        return unit;
    },
    getStatusList() {
        return [
            { statusCode: Constants.SELLER_STOCK_STATUS.ALL, statusText: 'สินค้าทั้งหมด' },
            { statusCode: Constants.SELLER_STOCK_STATUS.LIMITED, statusText: 'ขาย-มีสต๊อก' },
            { statusCode: Constants.SELLER_STOCK_STATUS.FACTORY, statusText: 'ขาย-สั่งจากโรงงาน' },
            { statusCode: Constants.SELLER_STOCK_STATUS.SUBLET, statusText: 'ขาย-สั่งจากร้านอื่น' },
            { statusCode: Constants.SELLER_STOCK_STATUS.NOTSALE, statusText: 'ยกเลิกการขาย' },
            { statusCode: Constants.SELLER_STOCK_STATUS.LATEST, statusText: 'สินค้าเพิ่มล่าสุด' }
        ];
    },
    initStockYearOption() {
        const currentYear = moment().year();
        const startYear = currentYear - 5;
        const option = [];

        for (let year = currentYear; year >= startYear; year--) {
            option.push({
                id: year,
                name: year
            });
        }

        return option;
    },
    getBranchSellerId() {
        const sellerInfo = JSON.parse(localStorage.shopInfo);

        if (sellerInfo) {
            return sellerInfo.sellerId;
        }

        return null;
    },
    getFacebookLink() {
        return 'https://facebook.com/yellowtire/'
    },
    setCartDataStorage(param) {
        localStorage.cartData = JSON.stringify(param);
    },
    getGlobalSearchTab() {
        let tab = 1;

        if (this.getQueryString('t')) {
            tab = Number(this.getQueryString('t'));

            if (isNaN(tab) || tab < 1 || tab > 3) {
                tab = 1;
            }
        }
        
        return tab;
    },
    setNoImg(e, type) {
        switch (type) {
            case 'product':
                e.target.src = '/img/no-product.jpg';
                break;

            case 'store':
                e.target.src = '/img/no-store.jpg';
                break;

            case 'blog':
                e.target.src = '/img/no-blog.jpg';
                break;

            case 'country':
                e.target.src = '/img/no-country.jpg';
                break;

            case 'tsi':
                e.target.src = '/img/no-tsi-data.jpg';
                break;

            case 'eco':
                e.target.src = '/img/no-eco-data.jpg';
                break;

            case 'brand':
                e.target.src = '/img/no-brand.jpg';
                break;
        }
    },
    getServiceInfoCheckbox() {
        const option = [
            { property: 'hasTireBalancing', text: 'ถ่วงล้อ'},
            { property: 'hasTireCenter', text: 'ตั้งศูนย์'},
            { property: 'hasNitrogen', text: 'เติมลมยางไนโตรเจน'},
            { property: 'hasPatchMushroom', text: 'ปะยางแบบดอกเห็ด'},
            { property: 'hasPatchStreamingCool', text: 'ปะยางแบบสตีมเย็น'},
            { property: 'hasPatchStreamingHeat', text: 'ปะยางแบบสตีมร้อน'},
            { property: 'hasPatchSilkPlug', text: 'ปะยางแบบแทงใยไหม/ตัวหนอน'}
        ];

        return option;
    },
    getOptionGapDay() {
        const option = [];

        for (let day = 0; day <= 7; day++) {
            option.push({
                key: day.toString(),
                name: day + ' วัน'
            });
        }

        return option;
    },
    getOptionQty() {
        const option = [];

        for (let qty = 1; qty <= 4; qty++) {
            option.push({
                key: qty.toString(),
                name: qty + ' เส้น'
            });
        }

        return option;
    },
    splitDeliveryTracking(text) {
        const parts = text.split(',');

        for (var i = 2; i < parts.length; i += 2) {
            if (i !== parts.length - 1) {
                parts.splice(i, 0, '<br />');
            }
        }

        let result = parts.join(',');
        result = result.replace(/<br \/>,/, '<br />');

        return result;
    }
}

export default helper;