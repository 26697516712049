<template>
    <div class="checkbox-control" :class="{ 'readonly': readonly }" @click="toggle()">
        <div class="checkbox-button" 
            :class="{ 'checked': isEnabled }"
            :style="{ '--boxColor': boxColor, '--iconColor': iconColor }">
            <b-icon icon="check2" v-if="isEnabled"></b-icon>
        </div>
        <div class="checkbox-label" :class="{ 'no-label': !$slots.default }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isEnabled: false
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        boxColor: {
            type: String,
            default: '#fff'
        },
        borderColor: {
            type: String,
            default: '#666'
        },
        iconColor: {
            type: String,
            default: '#333'
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value(curr) {
            this.isEnabled = curr;
        },
        isEnabled(curr) {
            this.$emit('input', curr);
            this.$emit('change', curr);
        }
    },
    mounted() {
        if (this.value) {
            this.isEnabled = this.value;
        }
    },
    methods: {
        toggle() {
            if (!this.readonly) {
                this.isEnabled = !this.isEnabled;
                this.$emit('afterTrigger', this.isEnabled);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

$checkBoxSize: 20px;
$boxColor: var(--boxColor);
$iconColor: var(--iconColor);

.checkbox-control {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    
    .checkbox-button {
        width: $checkBoxSize;
        height: $checkBoxSize;
        background: $boxColor;
        margin-top: 2px;
        border: 1px solid #666;
        @include transition(all 0.1s linear);

        &.checked {
            color: $iconColor;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
        }
    }

    .checkbox-label {
        margin-left: 10px;
        margin-top: 2px;
        font-size: 13px;
        flex: 1;

        &.no-label {
            margin-left: 0;
            flex: none;
        }
    }

    &.readonly {
        cursor: default;
    }
}
</style>