import Helper from '@/services/helper';
import UserService from '@/services/userService';

export default {
    gtagReportPurchaseProduct(gtag) {
        gtag.event('conversion', {
            'send_to': 'AW-775438363/eOKICMKGvYMYEJuA4fEC'
        });

        return false;
    },
    gtmViewProduct(gtm, product) {
        if (gtm.enabled()) {
            gtm.trackEvent({
                event: 'view_item',
                ecommerce: {
                    items: [
                        {
                            item_id: product.id,
                            item_name: [ product.brand, product.name, product.size ].join(' '),
                            item_brand: product.brand,
                            price: null,
                            quantity: 4
                        }
                    ]
                }
            });
        }
    },
    gtmAddCart(gtm, data) {
        if (gtm.enabled()) {
            gtm.trackEvent({
                event: 'add_to_cart',
                ecommerce: {
                    items: [
                        {
                            item_id: data.product.id,
                            item_name: [ data.product.brand, data.product.name, data.product.size ].join(' '),
                            item_brand: data.product.brand,
                            item_category: data.type,
                            item_category2: data.sellerProductId,
                            item_category3: data.storeId,
                            price: data.price ? parseFloat(data.price?.replace(/,/g, '')) : 0,
                            quantity: 4
                        }
                    ]
                  }
            });
        }
    },
    gtmBeginCheckout(gtm, finalPrice, items) {
        if (gtm.enabled()) {
            gtm.trackEvent({
                event: 'begin_checkout',
                ecommerce: {
                    currency: 'THB',
                    value: finalPrice ? parseFloat(finalPrice?.replace(/,/g, '')) : 0,
                    items: items
                  }
            });
        }
    },
    gtmPurchase(gtm, orderNumber, finalPrice, vatPrice, deliveryFee, items) {
        if (gtm.enabled()) {
            gtm.trackEvent({
                event: 'purchase',
                ecommerce: {
                    transaction_id: orderNumber,
                    value: finalPrice ? parseFloat(finalPrice?.replace(/,/g, '')) : 0,
                    tax: vatPrice ? parseFloat(vatPrice?.replace(/,/g, '')) : 0,
                    shipping: deliveryFee ? parseFloat(deliveryFee?.replace(/,/g, '')) : 0,
                    currency: 'THB',
                    items: items
                  }
            });
        }
    },
    async validateGTMPurchase(orderNumber) {
        const param = {
            orderNumber: orderNumber
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/validate-gtm-purchase', param, UserService.setHeaderUserToken());
        
        return result;
    },
    async trackGTMPurchase(orderNumber) {
        const param = {
            orderNumber: orderNumber
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/order/track-gtm-purchase', param, UserService.setHeaderUserToken());
        
        return result;
    }
}