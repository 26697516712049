<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-select-branch modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>เลือกสาขา</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="input-search">
                            <input type="text" v-model="keyword" @keyup.enter="getBranchList()" placeholder="พิมพ์ชื่อสาขาที่ต้องการค้นหา" />
                            <b-icon icon="search"></b-icon>
                        </div>

                        <div class="loading" v-if="!isResultReady">
                            <loader-spinner
								sizeWidth="20px"
								sizeHeight="20px">
							</loader-spinner>
                        </div>

                        <div class="branch-list">
                            <div class="item" v-for="(item, index) in branchList" :key="index" @click="selectBranch(item.sellerId)">
                                <div class="info">
                                    <p>{{ item.name }}</p>
                                    <address>{{ item.address }}</address>
                                </div>
                            
                                <button class="btn btn-warning square">เลือก</button>
                            </div>
                        </div>

                        <div class="no-data" v-if="branchList.length === 0 && isResultReady">
                            <b-icon icon="box-seam"></b-icon>
                            <span>ไม่พบสาขาที่ค้นหา</span>
                        </div>

                        <paging
                            @page="changePage($event)"
                            :activePage="activePage"
                            :totalPage="totalPage"
                            class="mt-3">
                        </paging>	
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import LoaderSpinner from '@/components/utility/LoaderSpinner';

export default {
    components: {
		LoaderSpinner
	},
    data() {
		return {
            isResultReady: false,
            isActive: false,
            activePage: 1,
			totalPage: 0,
            branchList: [],
            keyword: ''
		};
	},
	props: {
		isDisplay: Boolean
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    mounted() {
        this.getBranchList();  
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        async getBranchList() {
            this.branchList = [];

            this.isResultReady = false;
            const result = await SellerService.getBranchList(this.keyword, this.activePage);

            this.totalPage = result.data.totalPage;
            this.branchList = result.data.branchList;

            this.isResultReady = true;
        },
        async selectBranch(branchId) {
            let loader = this.$modalLoader.render();
            const result = await SellerService.changeBranch(branchId);

            if (result.data?.success) {
                SellerService.setSellerToken(result.data.token);
                    
                localStorage.setItem('shopInfo', JSON.stringify(result.data.info));
                localStorage.setItem('shopAcl', JSON.stringify(result.data.acl));
                
                location.reload();
            } else {
                this.$swal(Helper.warningAlert('ไม่สามารถเปลี่ยนสาขาได้', 'ดูเหมือนเกิดความขัดข้องในระหว่างการเชื่อมต่อข้อมูล โปรดทำการโหลดหน้าใหม่อีกครั้ง'));
                loader.hide();
            }
        },
        changePage(page) {
			this.activePage = page;
			this.getBranchList();
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-select-branch {
    max-width: 1000pxแ;

    .input-search {
        position: relative;
        display: flex;
        align-items: center;

        input {
            display: block;
            width: 100%;
            background: #fff;
            border: 1px solid #707070;
            padding: 8px 35px 8px 10px;
            outline: none;
        }

        .bi {
            position: absolute;
            color: #000;
            font-size: 18px;
            right: 10px;
            cursor: pointer;
        }
    }

    .loading {
        margin: 20px 0;
        text-align: center;
    }

    .branch-list {
        margin-top: 13px;

        .item {
            position: relative;
            margin-top: 10px;
            border-bottom: 1px solid #ddd;
            padding: 0 0 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            .info {
                p {
                    margin: 0;
                    font-size: 17px;
                    font-weight: bold;
                }

                address {
                    font-size: 13px;
                    line-height: 15px;
                    color: #777;
                    margin: 0;
                }
            }

            .btn {
                font-size: 13px;
                padding: 3px 12px;
            }
        }
    }
}
</style>