<template>
    <div class="time-picker">
        <div class="input">
            <input
                :class="inputClass"
                @click="toggleOption()"
                :value="time"
                :placeholder="placeholder"
                readonly />

            <button @click="clearTime()"><b-icon icon="x"></b-icon></button>
        </div>
        
        <div class="dropdown-wrapper" v-if="isShowOption">
            <div class="option">
                <span v-for="(item, index) in hours"
                    :key="index"
                    :class="{ 'active': selectedHour === item }"
                    @click="selectHour(item)">
                    {{ item }}
                </span>
            </div>
            <div class="option">
                <span v-for="(item, index) in mins"
                    :key="index"
                    :class="{ 'active': selectedMin === item }"
                    @click="selectMin(item)">
                    {{ item }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Helper from '@/services/helper';

export default {
    data() {
        return {
            time: '',
            hours: this.initOption(23),
            mins: this.initOption(59),
            selectedHour: '00',
            selectedMin: '00',
            isShowOption: false
        };
    },
    props: {
        value: {
            required: true
        },
        inputClass: {
            type: [String, Array],
            required: false,
            default: () => ''
        },
        placeholder: {
            type: String,
            required: false,
            default: () => '00:00'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keyup', this.handleClickOutside);

        if (this.value) {
            this.time = this.value;
            const timeArray = this.time.split(':');

            this.selectedHour = timeArray[0];
            this.selectedMin = timeArray[1];
        }
    },
    destroyed() {
        document.removeEventListener('keyup', this.handleClickOutside);
        document.removeEventListener('click', this.handleClickOutside);
    },
    watch: {
        time(curr) {
            this.$emit('input', curr);
        }
    },
    methods: {
        handleClickOutside(e) {
            if (this.$el.contains(e.target)) {
                return;
            }

            this.isShowOption = false;
        },
        initOption(maxNumber) {
            const option = [];

            for (let index = 0; index <= maxNumber; index++) {
                option.push(Helper.getLeadingZero(index, 2));
            }

            return option;
        },
        toggleOption() {
            if (!this.disabled) {
                this.isShowOption = !this.isShowOption;
            }
        },
        clearTime() {
            if (!this.disabled) {
                this.time = '';
            }
        },
        selectHour(item) {
            this.selectedHour = item;
            this.setTimeDisplay();
        },
        selectMin(item) {
            this.selectedMin = item;
            this.setTimeDisplay();
            this.isShowOption = false;
        },
        setTimeDisplay() {
            this.time = this.selectedHour + ':' + this.selectedMin;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.time-picker {
    .input {
        position: relative;
        display: flex;
        align-items: center;

        input {
            display: block;
            width: 100%;
            outline: none;
            border: 1px solid #666;
            padding: 7px 15px;
            overflow: hidden;
            cursor: pointer;

            &[readonly] {
                background: #fff;   
            }

            &.invalid {
                border-color: $inputErrorColor;
            }

            &.readonly {
                background: #eee;
                cursor: not-allowed;
            }
        }

        button {
            position: absolute;
            color: #aaa;
            right: 5px;
            border: none;
            background: none;
            outline: none;
            padding: 0;
            margin: 0;
        }
    }

    .dropdown-wrapper {
        display: flex;
        position: absolute;
        width: 180px;
        height: 200px;
        z-index: 1001;
        background: #fff;
        border: 1px solid #999;
        margin-top: 1px;
        @include boxShadow(0 0 5px rgba(0, 0, 0, 0.1));

        .option {
            width: 50%;
            height: 100%;
            overflow-y: auto;
            text-align: center;

            span {
                display: block;
                padding: 3px;
                cursor: pointer;

                &:hover, &.active {
                    background: #3c77cd;
                    color: #fff;
                }
            }
        }
    }
}
</style>