<template>
    <div class="paging" v-if="pageList.length > 1">
        <button class="item" @click="changePage(activePage - 1)" v-if="activePage > 1">
            <b-icon icon="chevron-left"></b-icon>
        </button>
        <span v-for="(page, index) in pageList" :key="index">
            <template v-if="page == 0">
                <button class="item skip">
                    ...
                </button>
            </template>
            <template v-else>
                <button class="item" :class="page == activePage ? 'active' : ''" @click="changePage(page)">
                    {{ page }}
                </button>
            </template>
        </span>
        <button class="item" @click="changePage(activePage + 1)" v-if="activePage < totalPage">
            <b-icon icon="chevron-right"></b-icon>
        </button>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
    data() {
        return {
            pageList: []
        }
    },
    props: {
        activePage:  {
            type: Number,
            required: true
        },
        totalPage: {
            type: Number,
            required: true
        }
    },
    watch: {
        activePage() {
            this.setupPageList();
        },
        totalPage() {
            this.setupPageList();
        }
    },
    mounted() {
        this.setupPageList();
    },
    methods: {
        setupPageList() {
            let isSkip = false;
            const size = 2;
            this.pageList = [];

            for (let page = 1; page <= this.totalPage; page++) {
                if (page == 1 || page == this.totalPage || page == this.activePage || ((this.activePage - page) <= size && (page - this.activePage) <= size)) {
                    isSkip = false;
                    this.pageList.push(page);
                } else {
                    if (!isSkip) {
                        isSkip = true;
                        this.pageList.push(0);
                    }
                }
            }
        },
        changePage(page) {
            const scrollTimer = 200;

            $('html, body').animate({ scrollTop: 0 }, scrollTimer);

            setTimeout(() => {
                this.$emit('page', page);
            }, scrollTimer);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.paging {
    overflow: hidden;
    text-align: center;
    
    .item {
        display: inline-block;
        margin: 0 7px;
        padding: 2px 5px;
        font-size: 12px;
        font-weight: bold;
        background: none;
        border: 1px solid #333;
        outline: none;
        color: #333;
        min-width: 28px;

        @media only screen and (max-width: $screenExtraSmall) {
            margin: 2px;
            font-weight: normal;
            min-width: 22px;
        }

        &.active {
            background: $mainColor;
            color: #000;
        }

        &.skip {
            margin: 0 5px;
            cursor: default;
            border: 1px solid transparent;

            @media only screen and (max-width: $screenExtraSmall) {
                margin: 0;
            }
        }
    }
}
</style>