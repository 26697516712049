<template>
    <transition name="modal" v-if="isActive">
        <perfect-scrollbar class="modal-mask">
            <div class="modal-select-seller modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4>
                            <b-icon icon="pencil-square"></b-icon>
                            <span>เลือกร้านค้า</span>
                        </h4>

                        <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
                    </div>
                    <div class="modal-body">
                        <div class="input-search">
                            <input type="text" v-model="keyword" @keyup.enter="getSellerList()" placeholder="พิมพ์ชื่อร้านค้าที่ต้องการค้นหา" />
                            <b-icon icon="search"></b-icon>
                        </div>

                        <div class="loading" v-if="!isResultReady">
                            <loader-spinner
								sizeWidth="20px"
								sizeHeight="20px">
							</loader-spinner>
                        </div>

                        <div class="seller-list">
                            <div class="item" v-for="(item, index) in sellerList" :key="index" @click="selectSeller(item.sellerId)">
                                <div class="info">
                                    <div class="picture">
                                        <img :src="item.picture" :alt="item.name" :title="item.name" @error="helper.setNoImg($event, 'store')" />
                                    </div>
                                    <div class="text">
                                        <p>{{ item.name }}</p>
                                        <span>สาขา: {{ item.branch }}</span>
                                        <address>{{ item.address }}</address>

                                        <button class="btn btn-warning square">เลือก</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="no-data" v-if="sellerList.length === 0 && isResultReady && !isStartSearch">
                            <b-icon icon="search"></b-icon>
                            <span>พิมพ์ชื่อร้านค้าที่ต้องการ</span>
                        </div>

                        <div class="no-data" v-if="sellerList.length === 0 && isResultReady && isStartSearch">
                            <b-icon icon="box-seam"></b-icon>
                            <span>ไม่พบสาขาที่ค้นหา</span>
                        </div>

                        <paging
                            @page="changePage($event)"
                            :activePage="activePage"
                            :totalPage="totalPage"
                            class="mt-3">
                        </paging>	
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </transition>
</template>

<script>
import Helper from '@/services/helper';
import SellerService from '@/services/sellerService';
import LoaderSpinner from '@/components/utility/LoaderSpinner';

export default {
    components: {
		LoaderSpinner
	},
    data() {
		return {
            helper: Helper,
            isStartSearch: false,
            isResultReady: true,
            isActive: false,
            activePage: 1,
			totalPage: 0,
            sellerList: [],
            keyword: ''
		};
	},
	props: {
		isDisplay: Boolean
	},
    watch: {
        async isDisplay(value) {
            this.isActive = value;
            this.$root.$emit('rootSetContentScrollable', value);
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
	methods: {
        hide() {
            this.$emit('close');
        },
        async getSellerList() {
            this.sellerList = [];

            this.isResultReady = false;
            const result = await SellerService.getSellerList(this.keyword, this.activePage);

            this.totalPage = result.data.totalPage;
            this.sellerList = result.data.sellerList;

            this.isResultReady = true;
        },
        async selectSeller(sellerId) {
            let loader = this.$modalLoader.render();
            const result = await SellerService.changeSeller(sellerId);

            if (result.data?.success) {
                SellerService.setSellerToken(result.data.token);
                    
                localStorage.setItem('shopInfo', JSON.stringify(result.data.info));
                localStorage.setItem('shopAcl', JSON.stringify(result.data.acl));
                
                location.reload();
            } else {
                this.$swal(Helper.warningAlert('ไม่สามารถเปลี่ยนสาขาได้', 'ดูเหมือนเกิดความขัดข้องในระหว่างการเชื่อมต่อข้อมูล โปรดทำการโหลดหน้าใหม่อีกครั้ง'));
                loader.hide();
            }
        },
        changePage(page) {
			this.activePage = page;
			this.getSellerList();
		},
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-select-seller {
    max-width: 1000px;

    .input-search {
        position: relative;
        display: flex;
        align-items: center;

        input {
            display: block;
            width: 100%;
            background: #fff;
            border: 1px solid #707070;
            padding: 8px 35px 8px 10px;
            outline: none;
        }

        .bi {
            position: absolute;
            color: #000;
            font-size: 18px;
            right: 10px;
            cursor: pointer;
        }
    }

    .loading {
        margin: 20px 0;
        text-align: center;
    }

    .seller-list {
        margin-top: 13px;

        .item {
            position: relative;
            margin-top: 10px;
            border-bottom: 1px solid #ddd;
            padding: 0 0 10px;
            cursor: pointer;

            &:hover {
                opacity: 0.7;
            }

            .info {
                display: flex;
                
                .picture {
                    width: 150px;

                    img {
                        display: block;
                        width: 100%;
                    }
                }

                .text {
                    flex: 1;
                    padding-left: 25px;

                    p {
                        margin: 0;
                        font-size: 17px;
                        line-height: 19px;
                        font-weight: bold;
                    }

                    span {
                        display: block;
                        font-size: 12px;
                        color: #999;
                        margin-bottom: 5px;
                    }

                    address {
                        font-size: 13px;
                        line-height: 15px;
                        color: #777;
                        margin: 0;
                    }

                    .btn {
                        margin-top: 15px;
                        font-size: 13px;
                        padding: 3px 12px;
                    }
                }
            }
        }
    }
}
</style>