<template>
    <transition name="modal" v-if="isActive">
        <div class="modal-full modal-mask" @click="hide()">
            <button class="btn-close" @click="hide()"><b-icon icon="x"></b-icon></button>
            <div class="block">
                <img :src="pictures[currentIndex]" :alt="name" :title="name" @click.stop />
            </div>
            <template v-if="pictures.length > 1">
                <button class="btn-nav btn-prev" @click="goPrev()" @click.stop><b-icon icon="chevron-left"></b-icon></button>
                <button class="btn-nav btn-next" @click="goNext()" @click.stop><b-icon icon="chevron-right"></b-icon></button>
            </template>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            isActive: false,
            currentIndex: -1,
            pictures: [],
            name: ''
        }
    },
    props: {
        isDisplay: Boolean,
        pictureList: {
            type: Array,
            required: true
        },
        pictureIndex: {
            type: Number,
            required: false,
            default: 0
        },
        pictureName: {
            type: String,
            required: false,
            default: ''
        }
    },
    watch: {
        isDisplay(value) {
            this.isActive = value;

            if (value) {
                this.show();
            }
        }
    },
    methods: {
        show() {
            this.isActive = true;
            this.pictures = this.pictureList;
            this.currentIndex = this.pictureIndex;
            this.name = this.pictureName;
        },
        hide() {
            this.$emit('close');
        },
        goPrev() {
            this.currentIndex--;

            if (this.currentIndex < 0) {
                this.currentIndex = this.pictures.length - 1;
            }
        },
        goNext() {
            this.currentIndex++;

            if (this.currentIndex == this.pictures.length) {
                this.currentIndex = 0;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.modal-full {
    .btn-close {
        position: absolute;
        z-index: 1;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.8);
        color: #fff;
        font-size: 34px;
        line-height: 30px;
        margin: 0;
        padding: 0;
        border: none;
        outline: none;
    }

    .btn-nav {
        position: absolute;
        z-index: 1;
        top: 50%;
        background: none;
        color: #fff;
        font-size: 34px;
        line-height: 30px;
        margin: 0;
        margin-top: -17px;
        padding: 0;
        border: none;
        outline: none;

        &.btn-prev {
            left: 10px;
        }

        &.btn-next {
            right: 10px;
        }
    }

    .block {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        img {
            max-width: 100%;
        }
    }
}
</style>