<template>
    <div class="take-picture" :style="{ '--buttonColor': buttonColor }">
        <input type="file" ref="fileInput" accept="image/*" @change="handleCapture($event)" />
        <button class="btn-upload"><slot></slot></button>

        <div class="modal-crop modal-mask" v-if="isActive">
            <div class="form-crop">
                <h4>CROP IMAGE</h4>
                <button class="close-modal" @click="hide()"><b-icon icon="x"></b-icon></button>
                <vue-cropper 
                    ref="cropper"
                    :zoomOnWheel="false"
                    :aspect-ratio="aspectRatio"
                    :src="selectedImage">
                </vue-cropper>
                <div class="text-center mt-3">
                    <button class="btn btn-secondary btn-square" @click="cropImage()">ใช้รูปนี้</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
    components: { VueCropper},
    data() {
        return {
            isActive: false,
            selectedImage: null,
            errorMessage: ''
        };
    },
    props: {
        aspectRatio: {
            type: [ Object, Number ],
            required: false,
            default: null
        },
        fileErrorTitle: String,
        fileTypeErrorMessage: String,
        fileSizeErrorMessage: String,
        buttonColor: {
            type: String,
            required: false,
            default: '#555'
        }
    },
    watch: {
        selectedImage(curr) {
            this.$emit('input', curr);
        },
        isActive(value) {
            this.$root.$emit('rootSetContentScrollable', value);

            if (!value) {
                this.$refs.fileInput.value = null; // Prevent close modal and reload the same image
            }
        }
    },
    destroyed() {
        this.$root.$emit('rootSetContentScrollable', false);
    },
    methods: {
        hide() {
            this.isActive = false;
        },
        handleCapture(e) {
            const input = e.target.files[0];

            if (this.validateFileType(input) && this.validateFileSize(input)) {
                this.isActive = true;
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.selectedImage = event.target.result;
                    this.$refs.cropper.replace(event.target.result);
                };

                reader.readAsDataURL(input);
            }
            else {
                this.$swal({
                    title: this.fileErrorTitle,
                    text: this.errorMessage,
                    icon: 'error',
                    confirmButtonColor: '#41adc5'
                });
            }
        },
        validateFileType(input) {
            let valid = false;
            const fileTypeAllow = ['image/jpeg', 'image/png'];

            if (input != '' && input != null && typeof input != 'undefined') {
                for (var index = 0; index < fileTypeAllow.length; index++) {
                    if (input.type === fileTypeAllow[index]) {
                        valid = true;
                    }
                }

                if (!valid) {
                    this.errorMessage = this.fileTypeErrorMessage;
                }
            }
            else {
                valid = true;
            }

            return valid;
        },
        validateFileSize(input) {
            let valid = false;
            const fileSizeAllow = 10485760;

            if (input != '' && input != null && typeof input != 'undefined') {
                if (input.size <= fileSizeAllow) {
                    valid = true;
                }

                if (!valid) {
                    this.errorMessage = this.fileSizeErrorMessage;
                }
            }
            else {
                valid = true;
            }

            return valid;
        },
        cropImage() {
            this.cropImg = this.$emit('load', this.$refs.cropper.getCroppedCanvas().toDataURL());
            this.hide();
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

$buttonColor: var(--buttonColor);

.take-picture {
    position: relative;

    h4 {
        font-size: 16px;
        font-weight: bold;
        font-style: italic;
        letter-spacing: -1px;
        margin-bottom: 12px;
        color: #333;
    }

    input {
        position: absolute;
        z-index: 2;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }
    
    .btn-upload {
        position: relative;
        cursor: pointer;
        z-index: 1;
        width: 100%;
        height: auto;
        margin: 0;
        padding: 8px 5px;
        font-size: 13px;
        font-weight: normal;
        line-height: normal;
        color: #fff;
        background-color: $buttonColor;
        border: none;
    }

    .modal-crop {
        .form-crop {
            position: relative;
            background: #fff;
            width: 100%;
            max-width: 600px;
            padding: 20px;
            margin: 20px auto;

            .close-modal {
                position: absolute;
                z-index: 1;
                top: 10px;
                right: 10px;
                width: auto;
                color: #cc0000;
                background: none;
                font-size: 28px;
                line-height: 28px;
                margin: 0;
                padding: 0;
                border: none;
                outline: none;
            }
        }

        .btn {
            width: 100%;
            padding: 8px 5px;
            font-size: 14px;
        }
    }
}
</style>