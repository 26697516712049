import Helper from '@/services/helper';

export default {
    async requestOTP(tel, recaptcha) {
        const param = {
            tel: tel,
            recaptcha: recaptcha
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/request-otp', param);
        
        return result;
    },
    async submitOTP(mode, tel, otp, otpToken) {
        const param = {
            type: mode,
            tel: tel,
            otp: otp,
            otpToken: otpToken
        };

        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/submit-otp', param);
        
        return result;
    },
    async registerStore(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/register-store', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getBranchList(keyword, page) {
        const param = {
            keyword: keyword,
            page: page
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-branch-list', param, this.setHeaderSellerToken());
        
        return result;
    },
    async changeBranch(branchId) {
        const param = {
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/change-branch', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getSellerList(keyword, page) {
        const param = {
            keyword: keyword,
            page: page
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/all-seller-list', param, this.setHeaderSellerToken());
        
        return result;
    },
    async changeSeller(sellerId) {
        const param = {
            sellerId: sellerId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/change-seller', param, this.setHeaderSellerToken());
        
        return result;
    },
    async saveStoreData(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-store', param, this.setHeaderSellerToken());
        
        return result;
    },
    async saveDeliveryInfo(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-delivery-info', param, this.setHeaderSellerToken());
        
        return result;
    },
    async saveInstallationInfo(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-installation-info', param, this.setHeaderSellerToken());
        
        return result;
    },
    async saveStoreDecoration(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-decoration', param, this.setHeaderSellerToken());
        
        return result;
    },
    async checkStoreRegistration() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/check-registration-status', this.setHeaderSellerToken());
        
        return result;
    },
    async submitLogin(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/submit-login', param);
        
        return result;
    },
    async registerSellerPassword(hashTel, password) {
        const param = {
            key: hashTel,
            password: password
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/register-password', param);
        
        return result;
    },
    async resetSellerPassword(hashTel, password) {
        const param = {
            key: hashTel,
            password: password
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/reset-password', param);
        
        return result;
    },
    async getProfile() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/get-profile', this.setHeaderSellerToken());
        
        return result;
    },
    async getSettingInfo() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/get-setting-info', this.setHeaderSellerToken());
        
        return result;
    },
    async getDecoration() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/get-decoration', this.setHeaderSellerToken());
        
        return result;
    },
    async checkPictureProfile() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/check-picture-profile', this.setHeaderSellerToken());
        
        return result;
    },
    async getStaffList() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/staff-list', this.setHeaderSellerToken());
        
        return result;
    },
    async editStaff(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/edit-staff', param, this.setHeaderSellerToken());
        
        return result;
    },
    async addStaff(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/add-staff', param, this.setHeaderSellerToken());
        
        return result;
    },
    async changeStaffPermission(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/change-staff-permission', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getOrderList(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-order', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getOrderDetail(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-order-detail', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getOrderCount(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-order-count', param, this.setHeaderSellerToken());
        
        return result;
    },
    async exportOrder(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/export-order', param, this.setHeaderSellerToken());
        
        return result;
    },
    async exportProductIntegration(category) {
        const param = {
            category: category
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/export-product-integration', param, this.setHeaderSellerToken());
        
        return result;
    },
    async exportReport(param) {     
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/export-report', param, this.setHeaderSellerToken());
        
        return result;
    },
    async exportIncome(moneyTransferStatus, fromDate, toDate, branchId) {
        const param = {
            moneyTransferStatus: moneyTransferStatus,
            fromDate: fromDate,
            toDate: toDate,
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/export-income', param, this.setHeaderSellerToken());
        
        return result;
    },
    async confirmOrder(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/confirm-order', param, this.setHeaderSellerToken());
        
        return result;
    },
    async cancelOrder(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/cancel-order', param, this.setHeaderSellerToken());
        
        return result;
    },
    async confirmService(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/confirm-service', param, this.setHeaderSellerToken());
        
        return result;
    },
    async confirmDelivery(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/confirm-delivery', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getStock(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-stock', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getStockAll(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-stock-all', param, this.setHeaderSellerToken());
        
        return result;
    },
    async saveStock(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-stock', param, this.setHeaderSellerToken());
        
        return result;
    },
    async updateStockStatus(stockId, status) {
        const param = {
            id: stockId,
            status: status
        };

        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/update-stock-status', param, this.setHeaderSellerToken());
        
        return result;
    },
    async updateStockDisplay(stockId, display) {
        const param = {
            id: stockId,
            display: display
        };

        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/update-stock-display', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getStockDetail(stockId) {
        const param = {
            id: stockId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-stock-detail', param, this.setHeaderSellerToken());
        
        return result;
    },
    async deleteStock(stockId) {
        const param = {
            id: stockId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/delete-stock', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getStockPromotionV2() {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-seller-promotion', null, this.setHeaderSellerToken());
        
        return result;
    },
    async getStockPromotion(category, type) {
        const param = {
            category: category,
            type: type
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-shop-promotion', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getStockWarranty(type) {
        const param = {
            type: type
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-shop-warranty', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getStockService(type) {
        const param = {
            type: type
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-shop-service', param, this.setHeaderSellerToken());
        
        return result;
    },
    async savePromotion(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-promotion', param, this.setHeaderSellerToken());
        
        return result;
    },
    async saveWarranty(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-warranty', param, this.setHeaderSellerToken());
        
        return result;
    },
    async saveService(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-service', param, this.setHeaderSellerToken());
        
        return result;
    },
    async updatePromotionProduct(sellerProductId, category) {
        const param = {
            sellerProductId: sellerProductId,
            category: category
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/update-promotion-product', param, this.setHeaderSellerToken());
        
        return result;
    },
    async updateWarrantyProduct(sellerProductId) {
        const param = {
            sellerProductId: sellerProductId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/update-warranty-product', param, this.setHeaderSellerToken());
        
        return result;
    },
    async updateServiceProduct(sellerProductId) {
        const param = {
            sellerProductId: sellerProductId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/update-service-product', param, this.setHeaderSellerToken());
        
        return result;
    },
    async toggleProductPromotion(sellerProductId, isSelect, sellerPromotionId) {
        const param = {
            sellerProductId: sellerProductId,
            isSelect: isSelect,
            sellerPromotionId: sellerPromotionId
        }
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/toggle-product-promotion', param, this.setHeaderSellerToken());
        
        return result;
    },
    async toggleProductWarranty(sellerProductId, isSelect, sellerWarrantyId) {
        const param = {
            sellerProductId: sellerProductId,
            isSelect: isSelect,
            sellerWarrantyId: sellerWarrantyId
        }
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/toggle-product-warranty', param, this.setHeaderSellerToken());
        
        return result;
    },
    async toggleProductService(sellerProductId, isSelect, sellerServiceId) {
        const param = {
            sellerProductId: sellerProductId,
            isSelect: isSelect,
            sellerServiceId: sellerServiceId
        }
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/toggle-product-service', param, this.setHeaderSellerToken());
        
        return result;
    },
    async deletePromotion(sellerPromotionId) {
        const param = {
            sellerPromotionId: sellerPromotionId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/delete-promotion', param, this.setHeaderSellerToken());
        
        return result;
    },
    async deleteWarranty(sellerWarrantyId) {
        const param = {
            sellerWarrantyId: sellerWarrantyId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/delete-warranty', param, this.setHeaderSellerToken());
        
        return result;
    },
    async deleteService(sellerServiceId) {
        const param = {
            sellerServiceId: sellerServiceId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/delete-service', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getSummaryIncome(branchId) {
        const param = {
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-summary-income', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getIncomeTransaction(moneyTransferStatus, fromDate, toDate, page, branchId) {
        const param = {
            moneyTransferStatus: moneyTransferStatus,
            fromDate: fromDate,
            toDate: toDate,
            page: page,
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-income-transaction', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getHoliday() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/get-holiday', this.setHeaderSellerToken());
        
        return result;
    },
    async saveHoliday(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-holiday', param, this.setHeaderSellerToken());
        
        return result;
    },
    async removeHoliday(dateKey) {
        const param = {
            dateKey: dateKey
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/remove-holiday', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getOverallRating(branchId) {
        const param = {
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-overall-rating', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReviewList(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-review-list', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReportSale(page, fromDate, toDate, branchId) {
        const param = this.setupReportParam(page, fromDate, toDate, branchId, null);
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-report-sale', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReportProductRemain(page, fromDate, toDate, branchId, productStatus) {
        const param = this.setupReportParam(page, fromDate, toDate, branchId, productStatus);
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-report-product-remain', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReportPromotion(page, fromDate, toDate, branchId) {
        const param = this.setupReportParam(page, fromDate, toDate, branchId, null);
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-report-promotion', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReportProductPromotion(page, fromDate, toDate, branchId) {
        const param = this.setupReportParam(page, fromDate, toDate, branchId, null);
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-report-product-promotion', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReportProductMovement(page, fromDate, toDate, branchId) {
        const param = this.setupReportParam(page, fromDate, toDate, branchId, null);
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-report-product-movement', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReportSystem(page, fromDate, toDate, branchId) {
        const param = this.setupReportParam(page, fromDate, toDate, branchId, null);
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-report-system', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getReportCustomer(page, fromDate, toDate, branchId) {
        const param = this.setupReportParam(page, fromDate, toDate, branchId, null);
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-report-customer', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getDashboard(fromDate, toDate, branchId) {
        const param = {
            fromDate: fromDate,
            toDate: toDate,
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-dashboard', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getDashboardRankingSale(fromDate, toDate, branchId) {
        const param = {
            fromDate: fromDate,
            toDate: toDate,
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-dashboard-ranking-sale', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getRankingPopularTire(type, fromDate, toDate, branchId) {
        const param = {
            fromDate: fromDate,
            toDate: toDate,
            branchId: branchId
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-dashboard-popular-tire-' + type, param, this.setHeaderSellerToken());
        
        return result;
    },
    setupReportParam(page, fromDate, toDate, branchId, productStatus) {
        return {
            page: page,
            fromDate: fromDate,
            toDate: toDate,
            branchId: branchId,
            productStatus: productStatus
        };
    },
    async downloadExcelFile(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/download-stock-excel', param, this.setHeaderSellerToken());
        
        return result;
    },
    async uploadExcelFile(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/upload-stock-excel', param, this.setHeaderSellerToken());
        
        return result;
    },
    async proceedMassUpdate(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/proceed-mass-stock-update', param, this.setHeaderSellerToken());
        
        return result;
    },
    async submitLogout(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/logout', param, this.setHeaderSellerToken());
        
        return result;
    },
    setSellerToken(token) {
        localStorage.setItem('sellerTokenV2', token);
    },
    clearSellerToken() {
        localStorage.removeItem('sellerTokenV2');
        localStorage.removeItem('shopInfo');
        localStorage.removeItem('shopAcl');
    },
    setHeaderSellerToken() {
        return {
            Authorization: 'Bearer ' + localStorage.getItem('sellerTokenV2')
        };
    },
    getStaffACL(key) {
        return localStorage.getItem('shopAcl') ? JSON.parse(localStorage.getItem('shopAcl'))[key] : false;
    },
    canAccessOrderMenu() {
        return this.canManageOrder() || this.canConfirmService() || this.canConfirmDelivery();
    },
    canManageOrder() {
        return this.getStaffACL('manageOrder');
    },
    canConfirmService() {
        return this.getStaffACL('confirmService');
    },
    canConfirmDelivery() {
        return this.getStaffACL('confirmDelivery');
    },
    canAccessProductMenu() {
        return this.canManageProduct() || this.canManageExcelProduct() || this.canManagePromotion();
    },
    canManageProduct() {
        return this.getStaffACL('manageProduct');
    },
    canManageExcelProduct() {
        return this.getStaffACL('manageExcelproduct');
    },
    canManagePromotion() {
        return this.getStaffACL('managePromotion');
    },
    canAccessAccountantMenu() {
        return this.getStaffACL('manageAccounting');
    },
    canAccessDashboardMenu() {
        return this.canManageDashboard() || this.canAccessReport() || this.canAccessRating();
    },
    canManageDashboard() {
        return this.getStaffACL('manageDashboard');
    },
    canAccessReport() {
        return this.getStaffACL('manageReport');
    },
    canAccessRating() {
        return this.getStaffACL('manageRating');
    },
    canAccessStoreMenu() {
        return this.canManageStoreProfile() || this.canManageSetting() || this.canManageStaff() || this.canManageDecoration() || this.canManageHoliday();
    },
    canManageStoreProfile() {
        return this.getStaffACL('manageStoreProfile');
    },
    canManageSetting() {
        return this.getStaffACL('manageSetting');
    },
    canManageStaff() {
        return this.getStaffACL('manageStaff');
    },
    canManageDecoration() {
        return this.getStaffACL('manageDecoration');
    },
    canManageHoliday() {
        return this.getStaffACL('manageHoliday');
    },
    isSellerLogin() {
        return localStorage.getItem('sellerTokenV2') !== null && localStorage.getItem('sellerTokenV2') !== '';
    },
    async getDeliveryAreaRate(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/get-delivery-area-rate', param, this.setHeaderSellerToken());
        
        return result;
    },
    async getProductForSetting(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/products-for-setting', param, this.setHeaderSellerToken());
        
        return result;
    },
    async assignAreaRate(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-delivery-area-rate', param, this.setHeaderSellerToken());
        
        return result;
    },
    async assignProductDeliveryRate(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-delivery-product-rate', param, this.setHeaderSellerToken());
        
        return result;
    },
    async assignDayPrepareProduct(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-day-prepare-product', param, this.setHeaderSellerToken());
        
        return result;
    },
    async assignMinQty(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/save-product-min-qty', param, this.setHeaderSellerToken());
        
        return result;
    },        
    async getDeliveryAreaSummary() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/seller/get-delivery-area-summary', this.setHeaderSellerToken());

        return result;
    },
    async assignPrepaidProduct(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/seller/assign-prepaid-product', param, this.setHeaderSellerToken());
        
        return result;
    },
}