<template>
    <div>
        <div class="single-upload" :class="classWrapper">
            <input type="file" ref="file" @click="handleClearFile()" @change="handleFileUpload()" />
            <div class="text">
                <slot></slot>
            </div>
        </div>
        <div :class="classError" v-if="error.fileType">{{ fileTypeErrorText }}</div>
        <div :class="classError" v-if="error.fileSize">{{ fileSizeErrorText }}</div>
    </div>
</template>

<script>
export default {
    props: {
        placeholder: String,
        classWrapper: String,
        classError: String,
        fileTypeErrorText: String,
        fileTypeAllow: Array,
        fileSizeErrorText: String,
        fileSizeAllow: Number
    },
    data() {
        return {
            file: null,
            error: this.initValidateFile()
        };
    },
    methods: {
        initValidateFile() {
            return { fileType: false, fileSize: false };
        },
        handleClearFile() {
            setTimeout(() => {
                this.$refs.file.value = '';
            });
        },
        handleFileUpload() {
            let input = this.$refs.file.files[0];

            this.error = this.initValidateFile();

            if (this.validateFileType(input) && this.validateFileSize(input)) {
                this.$emit('input', input);
            }
        },
        validateFileType(input) {
            var valid = false;

            if (input != '' && input != null && typeof input != 'undefined') {
                if (this.fileTypeAllow.length > 0) {
                    for (var index = 0; index < this.fileTypeAllow.length; index++) {
                        if (input.type === this.fileTypeAllow[index]) {
                            valid = true;
                        }
                    }

                    if (!valid) {
                        this.error.fileType = true;
                    }
                }
                else {
                    valid = true;
                }
            }
            else {
                valid = true;
            }

            return valid;
        },
        validateFileSize(input) {
            let valid = false;

            if (input != '' && input != null && typeof input != 'undefined') {
                if (this.fileSizeAllow != '' && this.fileSizeAllow != null && typeof this.fileSizeAllow != 'undefined') {
                    if (this.fileSizeAllow >= input.size) {
                        valid = true;
                    }

                    if (!valid) {
                        this.error.fileSize = true;
                    }
                }
                else {
                    valid = true;
                }
            }
            else {
                valid = true;
            }

            return valid;
        }
    }
};
</script>

<style lang="scss" scoped>
.single-upload {
    position: relative;
    display: inline-block;
    width: 100%;

    input {
        position: absolute;
        z-index: 2;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }
    
    .text {
        position: relative;
        cursor: pointer;
        z-index: 1;
        width: 100%;
        height: auto;
        margin: 0;
        font-weight: normal;
        line-height: normal;
        color: #000;
    }
}
</style>