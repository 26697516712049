const AppUtility = {
    install (Vue) {
        Vue.prototype.$AppUtility = {
            inputOnlyNumber(e) {
                e = (e) ? e : window.event;
                const charCode = (e.which) ? e.which : e.keyCode;
        
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    e.preventDefault();
                }
                else {
                    return true;
                }
            },
            restrictInputLicensePlate(e) {
                const numeric = /[ก-ฮ0-9]/;
                const keyChar = String.fromCharCode(e.which || e.keyCode);

                if (!numeric.test(keyChar)) {
                    e.preventDefault();
                }
                else {
                    return true;
                }
            }
        };
    }
}

export default AppUtility