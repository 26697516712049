<template>
    <div class="template-seller">
        <seller-header></seller-header>

        <div class="layout-body">
            <slot />
        </div>
    </div>
</template>

<script>
import MixinContentScroll from '@/components/layouts/mixins/contentScroll';
import SellerHeader from '@/components/seller/Header';

export default {
    mixins: [ MixinContentScroll ],
    components: {
        SellerHeader
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.template-seller {
    position: relative;
    overflow: hidden;
	max-width: 100%;
    min-width: 340px;
    min-height: 100%;
    margin: auto;
    background: #fff;

    .layout-body {
        margin-top: 62px;
        padding: 0;
    }
}
</style>