<template>
    <div class="typeahead">
        <input
            ref="input"
            type="text"
            :class="[inputClass]"
            :placeholder="placeholder"
            :value="inputValue"
            :readonly="readonly"
            @focus="handleFocus()"
            @focusout="handleBlur($event)"
            @input="handleInput($event.target.value)"
            autocomplete="off"
        />
        <perfect-scrollbar
            class="autcomplete-list shadow"
            v-show="!readonly && isFocused && listData.length > 0 && matchData.length > 0"
        >
            <span
                v-for="(item, index) in matchData" :key="index"
                tabindex="0"
                class="autocomplete-item"
                @click="handleHit(item)"
            >
                {{ item }}
            </span>
        </perfect-scrollbar>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String
        },
        listData: {
            type: Array,
            required: true,
            validator: d => d instanceof Array
        },
        inputClass: {
            type: Array,
            default: null
        },
        readonly: {
            type: Boolean,
            default: false
        },
        maxMatches: {
            type: Number,
            default: 10
        },
        minMatchingChars: {
            type: Number,
            default: 1
        },
        placeholder: String
    },
    watch: {
        inputValue(value) {
            this.$emit('input', value);
        },
    },
    mounted() {
        this.initValue(this.value);
    },
    computed: {
        escapedQuery() {
            return this.escapeRegExp(this.sanitize(this.inputValue));
        },
        matchData() {
            if (this.inputValue.length === 0 || this.inputValue.length < this.minMatchingChars) {
                return [];
            }

            const regex = new RegExp(this.escapedQuery, 'gi');

            return this.listData
                .filter(i => i.match(regex) !== null)
                .sort((a, b) => {
                    const aIndex = a.indexOf(a.match(regex)[0]);
                    const bIndex = b.indexOf(b.match(regex)[0]);
                    if (aIndex < bIndex) { return -1 }
                    if (aIndex > bIndex) { return 1 }
                    return 0;
                }).slice(0, this.maxMatches);
        }
    },
    methods: {
        initValue(value) {
            if (value) {
                this.inputValue = value;
            }
        },
        sanitize(text) {
            return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
        },
        escapeRegExp(str) {
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        },
        handleFocus() {
            this.isFocused = true;
            this.inputValue = '';
        },
        handleHit(item) {
            this.inputValue = item;
            this.$emit('hit', item);
            this.$refs.input.blur();
            this.isFocused = false;
        },
        handleInput(newValue) {
            this.inputValue = newValue.replace('อ.', '').replace('ต.', '').replace('จ.', '');

            if (typeof this.value !== 'undefined') {
                this.$emit('input', newValue);
            }
        },
        handleBlur(e) {
            if (!this.readonly) {
                this.$emit('blur', e);

                const target = e.relatedTarget;

                if (target && target.classList.contains('autocomplete-item')) {
                    return;
                }
            }

            this.isFocused = false;
        }
    },
    data() {
        return {
            isFocused: false,
            inputValue: ''
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.typeahead {
    position: relative;
    width: 100%;

    .autcomplete-list {
        position: absolute;
        background: #fff;
        left: 0;
        right: 0;
        overflow: hidden;
        margin-top: 1px;
        z-index: 1001;
        height: auto;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        border: 1px solid $inputBorderColor;
        @include transition(none);
        @include boxShadow(0 0 5px rgba(0, 0, 0, 0.2));

        .autocomplete-item {
            @include transition(none);
            @include borderRadius(0);
            border-bottom: 1px dashed $inputBorderColor;
            padding: 7px 15px;
            font-size: 14px;
            cursor: pointer;
            outline: none;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background: rgb(32, 97, 158);
                color: #fff;
            }
        }
    }
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    
    &:hover {
        background: #555;
    }
}
</style>