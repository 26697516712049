import Helper from '@/services/helper';

export default {
    async submitLogin(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/login', param);
        
        return result;
    },
    async submitLoginFacebook(fbResult, authResult) {
        const param = {
            fbProfile: fbResult,
            fbAuth: authResult
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/login-by-facebook', param);
        
        return result;
    },
    async submitLoginGoogle(token) {
        const param = {
            accessToken: token
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/login-by-google', param);
        
        return result;
    },
    async submitLogout(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/logout', param);
        
        return result;
    },
    async submitResetPassword(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/reset-password', param);
        
        return result;
    },
    async getResetPasswordContent(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/reset-password-data', param);
        
        return result;
    },
    async requestOTP(tel, recaptcha) {
        const param = {
            tel: tel,
            recaptcha: recaptcha
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/request-otp', param);
        
        return result;
    },
    async submitOTP(mode, tel, otp, token) {
        const param = {
            type: mode,
            tel: tel,
            otp: otp,
            token: token
        };

        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/submit-otp', param, this.setHeaderUserToken());
        
        return result;
    },
    async registerGeneralUserPassword(hashTel, username, password) {
        const param = {
            key: hashTel,
            username: username,
            password: password
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/register-user-password', param);
        
        return result;
    },
    async resetGeneralUserPassword(hashTel, password) {
        const param = {
            key: hashTel,
            password: password
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/reset-user-password', param);
        
        return result;
    },
    async getProfile() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/user/get-profile', this.setHeaderUserToken());
        
        return result;
    },
    async getCar() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/user/get-car', this.setHeaderUserToken());
        
        return result;
    },
    async getPrivacyProfile() {
        const result = await Helper.getAPI(Helper.apiUrl(), '/api/user/get-privacy', this.setHeaderUserToken());
        
        return result;
    },
    async getOrderHistory(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/get-order-history', param, this.setHeaderUserToken());
        
        return result;
    },
    async feedbackRating(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/feedback-rating', param, this.setHeaderUserToken());
        
        return result;
    },
    async changePassword(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/change-password', param, this.setHeaderUserToken());
        
        return result;
    },
    async addCar(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/add-car', param, this.setHeaderUserToken());
        
        return result;
    },
    async deleteCar(id) {
        const param = {
            id: id
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/delete-car', param, this.setHeaderUserToken());
        
        return result;
    },
    async saveName(name) {
        const param = {
            name: name
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/save-name', param, this.setHeaderUserToken());
        
        return result;
    },
    async saveEmail(email) {
        const param = {
            email: email
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/save-email', param, this.setHeaderUserToken());
        
        return result;
    },
    async saveBirthDate(birthDate) {
        const param = {
            birthDate: birthDate
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/save-birthdate', param, this.setHeaderUserToken());
        
        return result;
    },
    async saveGender(gender) {
        const param = {
            gender: gender
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/save-gender', param, this.setHeaderUserToken());
        
        return result;
    },
    async saveAddress(param) {
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/save-address', param, this.setHeaderUserToken());
        
        return result;
    },
    async savePrivacy(isAcceptPrivacy) {
        const param = {
            isAcceptPrivacy: isAcceptPrivacy
        };
        const result = await Helper.postAPI(Helper.apiUrl(), '/api/user/save-privacy', param, this.setHeaderUserToken());
        
        return result;
    },
    setUserToken(token) {
        localStorage.setItem('userToken', token);
    },
    clearUserToken() {
        localStorage.removeItem('userToken');
    },
    setUsername(userDisplay) {
        localStorage.setItem('userDisplay', userDisplay);
    },
    clearUsername() {
        localStorage.removeItem('userDisplay');
    },
    setHeaderUserToken() {
        return {
            Authorization: 'Bearer ' + localStorage.getItem('userToken')
        };
    },
    isUserLogin() {
        return localStorage.getItem('userToken') !== null && localStorage.getItem('userToken') !== '';
    }
}