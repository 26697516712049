<template>
    <div class="content-loader" v-if="isDisplay">
        <div class="loader"></div>
    </div>
</template>

<script>
export default {
    props: {
        isDisplay: Boolean
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.content-loader {
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    background: #fff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .loader {
        width: 35px;
        aspect-ratio: 1;
        border-radius: 50%;
        border: 2px solid;
        border-color: #888 #0000;
        animation: circleAnimate 0.5s infinite;
    }
}

@keyframes circleAnimate {
    to {
        @include transform(rotate(1turn));
    }
}

/* Firefox < 16 */
@-moz-keyframes highlightAnimate {
    to {
        @include transform(rotate(1turn));
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes circleAnimate {
    to {
        @include transform(rotate(1turn));
    }
}

/* Internet Explorer */
@-ms-keyframes circleAnimate {
    to {
        @include transform(rotate(1turn));
    }
}

/* Opera < 12.1 */
@-o-keyframes circleAnimate {
    to {
        @include transform(rotate(1turn));
    }
}
</style>