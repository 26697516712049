<template>
    <header class="seller-header">
        <div class="container">
            <div class="wrapper">
                <div class="menu-logo">
                    <button class="mobile-menu d-lg-block d-xl-none">
                        <b-icon icon="justify" @click="toggleMobileMenu()"></b-icon>
                    </button>
                    <a href="/" title="Yellowtire.com" class="logo d-none d-sm-block">
                        <img src="@/assets/logo-seller.png" alt="Yellow Tires" title="Yellow Tires" />
                    </a>
                </div>
                <div class="top-content" v-if="!isSellerLogin()">
                    <h2><b-icon icon="wrench-adjustable-circle" class="icon"></b-icon> เข้าสู่ระบบร้านค้า</h2>
                </div>
                <div class="top-content" v-if="isSellerLogin()">
                    <h2 v-if="!hasBranch"><b-icon icon="wrench-adjustable-circle" class="icon"></b-icon> จัดการร้านค้า</h2>
                    <h2 v-if="hasBranch"><b-icon icon="geo-fill" class="icon"></b-icon> {{ branchTitleName }}</h2>
                    <button @click="openSelectBranch()" v-if="hasBranch">เลือกสาขา</button>

                    <!-- YELLOWTIRE -->
                    <button @click="openSelectStore()" v-if="isOnlyYellowTire()">เลือกร้านค้า</button>
                </div>
            </div>
        </div>

        <modal-select-branch
            v-if="isSellerLogin()"
            :isDisplay="isOpenSelectBranch"
            @close="closeSelectBranch()">
        </modal-select-branch>

        <modal-select-seller
            v-if="isOnlyYellowTire()"
            :isDisplay="isOpenSelectSeller"
            @close="closeSelectSeller()">
        </modal-select-seller>
    </header>
</template>

<script>
import SellerService from '@/services/sellerService';
import ModalSelectBranch from '@/components/seller/ModalSelectBranch';
import ModalSelectSeller from '@/components/seller/ModalSelectSeller';

export default {
    components: {
        ModalSelectBranch,
        ModalSelectSeller
    },
    data() {
        return {
            isOpenSelectBranch: false,
            branchTitleName: 'สาขาสำนักงานใหญ่',
            hasBranch: false,
            isOpenSelectSeller: false,
        };
    },
    mounted() {
		this.displayBranch();
	},
    methods: {
        isSellerLogin() {
            return SellerService.isSellerLogin();
        },
        isOnlyYellowTire() {
            const sellerInfo = this.getSellerInfo();

            return SellerService.isSellerLogin() && sellerInfo?.user === 'yellowtire';
        },
        toggleMobileMenu() {
            this.$emit('toggleMobileMenu');
        },
        openSelectBranch() {
            this.isOpenSelectBranch = true;
        },
        closeSelectBranch() {
            this.isOpenSelectBranch = false;
        },
        openSelectStore() {
            this.isOpenSelectSeller = true;
        },
        closeSelectSeller() {
            this.isOpenSelectSeller = false;
        },
        displayBranch() {
            const sellerInfo = this.getSellerInfo();

            if (sellerInfo?.isShowBranch && sellerInfo?.user !== 'yellowtire') {
                this.hasBranch = true;
                this.branchTitleName = sellerInfo.branchName;
            }
		},
        getSellerInfo() {
            let sellerInfo = null;

            if (localStorage.shopInfo) {
				sellerInfo = JSON.parse(localStorage.shopInfo);
			}

            return sellerInfo;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.seller-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #333;
    z-index: 1000;

    .container {
        max-width: 1600px;
    }

    .wrapper {
        padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media only screen and (max-width: $screenExtraSmall) {
            padding: 10px 0;
        }

        .menu-logo {
            display: flex;
            align-items: center;

            .mobile-menu {
                background: none;
                border: 1px solid #aaa;
                font-size: 24px;
                color: #fff;
                margin-right: 10px;
            }
        }

        .logo {
            img {
                height: 32px;
                display: block;

                @media only screen and (max-width: $screenSmall) {
                    height: 24px;
                }
            }
        }
        
        .top-content {
            display: flex;
            align-items: center;

            h2 {
                color: #fff;
                margin: 0;
                font-size: 15px;
                display: flex;
                align-items: center;

                .icon {
                    margin-right: 7px;
                }
            }

            button {
                border: 1px solid #fff;
                background: none;
                color: #fff;
                font-size: 13px;
                padding: 2px 10px;
                margin-left: 15px;
            }
        }
    }
}
</style>